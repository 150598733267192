import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { DateTimePicker as MaterialDatePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

const PREFIX = 'DatePicker'

const classes = {
  picker: `${PREFIX}-picker`,
  wrapper: `${PREFIX}-wrapper`,
}

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.picker}`]: {
    width: '100%',
  },

    paddingTop: theme.spacing(2),
    overflow: 'hidden',

}))

function DateTimePicker(props: any) {
  const { value, min, name, onChange, required, setValid, disabled } = props
  const { t: genericT } = useTranslation('generic')
  const { t, i18n } = useTranslation('datePicker')
  const [error, setError] = useState('')
  const [initial, setInitial] = useState(true)

  useEffect(() => {
    if (initial) {
      setInitial(false)
      if (!required) return
    }
    if (value === null || typeof value === 'undefined') {
      setError(t('noDate'))
      setValid(false)
      return
    }
    if (DateTime.isDateTime(value)) {
      setError(t('noValidDate'))
      setValid(false)
      return
    }
    const minDateTime = DateTime.fromISO(min)
    if (DateTime.fromISO(value) < minDateTime) {
      setError(t('tooEarly', { minDate: minDateTime.toFormat(genericT('dateTimeFormat')) }))
      setValid(false)
      return
    }
    setValid(true)
    setError('')
  }, [t, value, min])
  const helperProps:any = {}
    if (error) {
      helperProps.error = true
      helperProps.helperText = error
    }
  return (
    <Root >
      <MaterialDatePicker
        className={classes.picker}
        renderInput={(subProps) => <TextField fullWidth {...{ ...subProps, InputLabelProps: { ...subProps.InputLabelProps, shrink: true } }} {...helperProps} label={`${props.t(name)} ${required ? '*' : ''}`} />}
        value={value ? DateTime.fromISO(value) : null}
        onChange={(date: DateTime|null) => {
          onChange(date?.toISO() || null)
        }}
        inputFormat={genericT('dateTimeFormat')}
        mask={genericT('dateTimeFormatMask')}
        ampm={false}
        disabled={disabled}
      />
    </Root>
  )
}

DateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  setValid: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
DateTimePicker.defaultProps = {
  disabled: false,
}

export default DateTimePicker
