import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { MouseEvent, Dispatch, SetStateAction, useCallback } from 'react'
import StyledBreadCrumbs, { ILinkStack, IProps } from './StyledBreadCrumps'

interface ICollapsedProps extends IProps {
  expanded: boolean,
  setExpanded: Dispatch<SetStateAction<boolean>>
}
function BreadCrumpCollapsed({ linkStack, color, expanded, setExpanded }: ICollapsedProps) {
  const handleClick = useCallback((evt: MouseEvent<HTMLButtonElement>) => {
    setExpanded((prev: boolean) => !prev)
  }, [setExpanded])
  const displayLinks: ILinkStack[] = [
    linkStack[0],
    { text: '...', onClick: handleClick },
    linkStack[linkStack.length - 1],
  ]

  return (
    <StyledBreadCrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
      color={color}
    >
      {
        displayLinks.map((link) => {
          const attributes: any = {}
          if (link.to) {
            attributes.component = Link
            attributes.to = link.to
          }
          if (link.onClick) {
            attributes.onClick = link.onClick
          }
          return (
            <Button
              color="inherit"
              key={`breadcrump-${link.text}`}
              variant="text"
              {...attributes}
            >
              {link.text}
            </Button>
          )
        })
      }
    </StyledBreadCrumbs>
  )
}

BreadCrumpCollapsed.propTypes = {
  linkStack: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string,
    text: PropTypes.string.isRequired,
  })).isRequired,
  className: PropTypes.string,
}
export default BreadCrumpCollapsed
