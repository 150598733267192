import { Button, Divider, Grid, Typography } from '@mui/material'
import { styled } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { yncoris } from '../../theme'
import useDateFormat from '../../lib/dateFormat'

const StyledFooterWrapper = styled('div')(({ theme }) => ({
  backgroundColor: yncoris.petrol,
  minHeight: '10vh',
  width: '100%',
  color: 'white',
  padding: theme.spacing(4),
  marginTop: theme.spacing(4),
}))
const LowerContainer = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(4),

}))
const CopyRightNotice = styled(Grid)(({ theme }) => ({
  textAlign: 'center',

}))
const ListButton = styled(Button)(({ theme }) => ({
  color: 'white',
  textAlign: 'center',
}))
export default function Footer() {
  const { t, i18n } = useTranslation('footer')

  const yearFormatter = useDateFormat({ year: 'numeric' })

  return (
    <StyledFooterWrapper>
      <Grid container spacing={2} alignItems="flex-start" justifyContent="center">
        <Grid item xs={12} sm={8} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <a href="https://www.yncoris.com/impressum" target="_blank" rel="noreferrer">
                <ListButton
                  fullWidth
                >
                  <div dangerouslySetInnerHTML={{ __html: t('imprint', { escapeValue: false }) }} />

                </ListButton>
              </a>
            </Grid>
            <Grid item xs={12} md={6}>
              <a href="https://www.yncoris.com/datenschutz" target="_blank" rel="noreferrer">
                <ListButton
                  fullWidth
                >
                  <div dangerouslySetInnerHTML={{ __html: t('dataPrivacy', { escapeValue: false }) }} />
                </ListButton>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={10} md={8}>
          <Divider />
        </Grid>
        <Grid item xs={12} sm={10} md={8}>
          <LowerContainer
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item>
              <Typography component="p" variant="body1">
                Tag Scanner v{window.feVersion}
              </Typography>
            </Grid>
          </LowerContainer>
        </Grid>
        <CopyRightNotice item xs={12} sm={10} md={8}>
          <Typography variant="body2"> &copy; {yearFormatter(new Date())} Yncoris GmbH & Co. KG
            - {t('allRightsReserved')}
          </Typography>
        </CopyRightNotice>
      </Grid>
    </StyledFooterWrapper>
  )
}
