import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Checkbox as MaterialCheckbox, FormControlLabel } from '@mui/material'

const PREFIX = 'Checkbox'

const classes = {
  dialogPaperList: `${PREFIX}-dialogPaperList`,
  formControlLabel: `${PREFIX}-formControlLabel`,
  wrapper: `${PREFIX}-wrapper`,
  checkBoxSmall: `${PREFIX}-checkBoxSmall`,
  gridContainer: `${PREFIX}-gridContainer`,
}

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.dialogPaperList}`]: {
    maxHeight: 200,
    overflow: 'auto',
  },

  [`& .${classes.formControlLabel}`]: {
    marginLeft: 0,
    marginRight: 0,
    // marginBottom: theme.spacing(1),
  },

  [`&.${classes.wrapper}`]: {
    // paddingTop: theme.spacing(4),
    overflow: 'hidden',
  },

  [`& .${classes.checkBoxSmall}`]: {
    margin: 0,
    padding: 0,
  },

  [`& .${classes.gridContainer}`]: {
    // margin: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginTop: 0,
  },
}))

function Checkbox(props: any) {
  const { t, name, value, label, onChange, disabled } = props

  return (
    <Root className={classes.wrapper}>
      <FormControlLabel
        labelPlacement="end"
        onChange={() => onChange(!value)}
        disabled={disabled}
        classes={{
          root: classes.formControlLabel,
        }}
        control={(
          <MaterialCheckbox
            color="primary"
            checked={value}
            classes={{
              root: classes.checkBoxSmall,
            }}
          />
        )}
        label={label || t(name)}
      />
    </Root>
  )
}

Checkbox.propTypes = {
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  onChange: PropTypes.func.isRequired,
}
Checkbox.defaultProps = {
  disabled: false,
}
export default Checkbox
