import { useEffect } from 'react'
import { FormControlLabel, Grid, Switch, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Dialog from 'framework/components/Dialog'
import { selectCookies, acceptCookies, getAcceptedCookies } from 'features/dataProtection'
import { AppDispatch } from '../../../app/store'
import { useAppDispatch } from '../../../app/hooks'

export default function CookieBanner(props: any) {
  const { t } = useTranslation('cookieBanner')
  const cookies = useSelector(selectCookies)
  const appDispatch = useAppDispatch()
  useEffect(() => {
    appDispatch(getAcceptedCookies())
  }, [])
  // const open = !cookies.necessary

  const onSubmit = () => {
    appDispatch(acceptCookies({ necessary: true }))
  }

  return (
    <Dialog
      t={t}
      open={!cookies.necessary}
      dialogTitle={t('cookies')}
      onlySubmitButton
      submitFunction={onSubmit}
      cancelFunction={() => {
        }}
      scroll="body"
    >
      <Grid container spacing={2}>
        <Grid item>
          <Typography>
            {t('cookieText')}
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={<Switch checked name="necessary" />}
            label={`${t('technicalNecessary')}`}
          />
        </Grid>
      </Grid>
    </Dialog>
  )
}
