// assumes we have structure data: [ {id: 'abc',...} , {...}]

const getArrayOrEmpty = (data: any) => data || []

const getAdditionalProps = (payload: any, items: any) => ({})
export const addToState = (state: any, key: string | number, payload: any) => ({
  ...state,
  [key]: getArrayOrEmpty(state[key]).concat([{ ...payload, ...getAdditionalProps(payload, state[key]) }]),
})
export const removeFromState = (state: any, key: string | number, payload: any) => {
  const index = state[key].findIndex((x: any) => x.id === payload.id)
  // console.log('delete', index)
  return {
    ...state,
    [key]: [
      ...state[key].slice(0, index),
      ...state[key].slice(index + 1),
    ],
  }
}
export const removeFromStateByProp = (state: any, key: string | number, property: string, payload: any) => {
  const index = state[key].findIndex((x: any) => x[property] === payload[property])
  // console.log('delete', index)
  return {
    ...state,
    [key]: [
      ...state[key].slice(0, index),
      ...state[key].slice(index + 1),
    ],
  }
}
// assumes we have structure data: [ {id: 'abc',...} , {...}]
export const editById = (state: any, key: string | number, payload: any) => ({
  ...state,
  [key]: state[key].map((item: any) => {
    if (item.id === payload.id || item.id === payload.oldId) {
      return { ...item, ...payload }
    }
    return item
  }),
})
export const setDataToNestedKey = (state: any, firstKey: string | number, secondKey: string | number, payload: any) => ({
  ...state,
  [firstKey]: { ...state[firstKey], [secondKey]: payload },
})

// sets data to a key value in state, state: { key: value }
export const setDataToKey = (state: any, key: string | number, payload: any) => ({
  ...state,
  [key]: payload,
})
// nested object means we have structure data { key: [values] }
export const addToNestedState = (state: any, outerKey: string | number, innerKey: string | number, payload: any) => ({
  ...state,
  [outerKey]: {
    ...state[outerKey],
    [innerKey]: getArrayOrEmpty(state[outerKey][innerKey]).concat([{ ...payload, ...getAdditionalProps(payload, state[outerKey][innerKey]) }]),
  },
})

// nested object means we have structure data { key: [values] }
export const editNestedStateById = (state: any, outerKey: string | number, innerKey: string | number, payload: any) => ({
  ...state,
  [outerKey]: {
    ...state[outerKey],
    [innerKey]: state[outerKey][innerKey].map((item: any) => {
      if (item.id === payload.id || item.id === payload.oldId) {
        return { ...item, ...payload }
      }
      return item
    }),
  },
})

export const removeFromNestedState = (state: any, outerKey: string | number, innerKey: string | number, payload: any) => {
  const index = state[outerKey][innerKey].findIndex((x: any) => x.id === payload.id)
  // console.log('delete inner', index)
  return {
    ...state,
    [outerKey]: {
      ...state[outerKey],
      [innerKey]: [
        ...state[outerKey][innerKey].slice(0, index),
        ...state[outerKey][innerKey].slice(index + 1),
      ],
    },
  }
}
