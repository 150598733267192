import { api } from 'app/apiClient'

export class RestModel {
  path = ''

  getItems = async () => api.get(`${this.path}`).json()

  createItem = async (payload: any) => api.post(this.path, { json: payload }).json()

  updateItem = async (payload: any) => api.put(this.path, { json: payload }).json()

  deleteItem = async (id: string) => api.delete(`${this.path}/${id}`)

  createOrUpdateItem(payload: any) {
    if (payload.isNew) {
      return this.createItem({ ...payload, id: undefined })
    }
    return this.updateItem(payload)
  }
}
