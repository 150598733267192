import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import authReducer from 'features/auth'
import dataProtection from 'features/dataProtection'
import locations from 'features/locations'
import notifications from 'features/notifications'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    dataProtection,
    locations,
    notifications,
  },
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
  >;
