/* eslint-disable */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AppThunk, RootState} from 'app/store'
import {IBaseModel} from "../api/baseModel";
import LoadStateModel from "../framework/LoadStateModel";
import LocationRestModel from "api/locations"
import {OptionsObject, SnackbarMessage} from "notistack";
import {ILocationModel} from "./locations";
import {addToState, removeFromStateByProp} from "../framework/lib/reducerHelper";


export interface NotificationState {
  notifications: SnackbarPayload[],
}

const initialState: NotificationState = {
  notifications: [],
}

interface SnackbarOptionsObject extends OptionsObject {
  autoClose?: boolean,
}
interface SnackbarPayload {
  key?: any,
  message: SnackbarMessage,
  options?: SnackbarOptionsObject,
  dismissed?: boolean
}

export const index = createSlice({
  name: 'notifications',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    displayNotification: (state, action: PayloadAction<SnackbarPayload>) => {
      const notification:SnackbarPayload = {
        message: action.payload.message,
        options: {...action.payload.options},
        key: action.payload.key || new Date().getTime() + Math.random()
      }
      return addToState(state,'notifications',notification)
      // return {
      //   ...state,
      //   notifications: [
      //     ...state.notifications,
      //     notification
      //   ]
      // }
      // state.locations = action.payload
      // state.loadState = LoadStateModel.LOADED
    },
    removeNotification: (state, action:PayloadAction<any>)  =>{
      return removeFromStateByProp(state,'notifications','key',{key: action.payload})
    }
  },

})
export const notificationSelector = (state:RootState) => state.notifications.notifications

export const displayNotification = (message: SnackbarMessage,options?:SnackbarOptionsObject):AppThunk => async (dispatch,getState)=> {

  dispatch(index.actions.displayNotification({
    message,
    options
  }))
}

export const removeNotification = (key: any):AppThunk => async (dispatch,getState)=> {
  dispatch(index.actions.removeNotification(key))
}

  export default index.reducer
