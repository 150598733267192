import { PropsWithChildren, SetStateAction, useEffect } from 'react'
import PropTypes from 'prop-types'
import LoadStateModel from 'framework/LoadStateModel'
import DataFetcher from './DataFetcher'
import useMultiDataLoader, { IPropsLoader } from './useMultiDataLoader'

export interface IProps extends IPropsLoader{
  variant?: 'skeleton' | 'circle'
  setIsReady?: (value:boolean)=>void | SetStateAction<boolean>
}
function MultiDataLoader({ children, requiredProps, parameters, setIsReady, ...rest }: PropsWithChildren<IProps>) {
  const loadState = useMultiDataLoader({ requiredProps, parameters })
  useEffect(() => {
    if (loadState === LoadStateModel.LOADED && typeof setIsReady === 'function') {
      setIsReady(true)
    }
  }, [loadState])
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {loadState === LoadStateModel.LOADED
          ? children || null
          : (<DataFetcher {...rest} loadState={loadState} />)}
    </>
  )
}

MultiDataLoader.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  requiredProps: PropTypes.arrayOf(PropTypes.any).isRequired,
  parameters: PropTypes.any,
  setIsReady: PropTypes.func,
}
MultiDataLoader.defaultProps = {
  // requiredNested: undefined,
}
export default MultiDataLoader
