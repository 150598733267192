import { useEffect } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Select from 'react-select'
import { emphasize, useTheme, styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import NoSsr from '@mui/material/NoSsr'
import TextField from '@mui/material/TextField'
import Paper from '@mui/material/Paper'
import Chip from '@mui/material/Chip'
import MenuItem from '@mui/material/MenuItem'
import CancelIcon from '@mui/icons-material/Cancel'
import Grid from '@mui/material/Grid'
import { useTranslation } from 'react-i18next'

const PREFIX = 'AutoComplete'

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  input: `${PREFIX}-input`,
  valueContainer: `${PREFIX}-valueContainer`,
  disabled: `${PREFIX}-disabled`,
  chip: `${PREFIX}-chip`,
  chipFocused: `${PREFIX}-chipFocused`,
  noOptionsMessage: `${PREFIX}-noOptionsMessage`,
  singleValue: `${PREFIX}-singleValue`,
  singleValueDisabled: `${PREFIX}-singleValueDisabled`,
  placeholder: `${PREFIX}-placeholder`,
  paper: `${PREFIX}-paper`,
  divider: `${PREFIX}-divider`,
  menuItemLogo: `${PREFIX}-menuItemLogo`,
  menuItemText: `${PREFIX}-menuItemText`,
}

const StyledWrapper = styled('div')((
  {
    theme,
  }
) => ({
  // paddingTop: theme.spacing(2),
  [`& .${classes.root}`]: {
    flexGrow: 1,
    height: 250,
    // minWidth: 290,
  },

  [`& .${classes.wrapper}`]: {
    paddingTop: theme.spacing(2),
  },

  [`& .${classes.input}`]: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },

  [`& .${classes.valueContainer}`]: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },

  [`& .${classes.disabled}`]: {
    color: 'rgba(0, 0, 0, 0.38)',
  },

  [`& .${classes.chip}`]: {
    margin: theme.spacing(0.5, 0.25),
  },

  [`& .${classes.chipFocused}`]: {
    backgroundColor: emphasize(
      theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },

  [`& .${classes.noOptionsMessage}`]: {
    padding: theme.spacing(1, 2),
  },

  [`& .${classes.singleValue}`]: {
    fontSize: 16,
  },

  [`& .${classes.singleValueDisabled}`]: {
    fontSize: 16,
    color: 'rgb(0,0,0,0.38)',
  },

  [`& .${classes.placeholder}`]: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },

  [`& .${classes.paper}`]: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    width: '100%',
    backgroundColor: 'rgb(241,241,241)',
  },

  [`& .${classes.divider}`]: {
    height: theme.spacing(2),
  },

  [`& .${classes.menuItemLogo}`]: {
    maxHeight: '2rem',
    maxWidth: '3rem',
    marginRight: theme.spacing(1),
  },

  [`& .${classes.menuItemText}`]: {
    wordBreak: 'break-all',
  },
}))

function NoOptionsMessage(props: any) {
  const { t } = useTranslation('select')
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {t('NoOptions')}
    </Typography>
  )
}

NoOptionsMessage.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * Props to be passed on to the wrapper.
   */
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
}

function inputComponent({ inputRef, ...props }: any) {
  return <div ref={inputRef} {...props} />
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
}

function Control(props: any) {
  const {
    children,
    innerProps,
    innerRef,
    isDisabled,
    selectProps: { TextFieldProps },
  } = props
  return (
    <TextField
      fullWidth
      disabled={isDisabled}
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  )
}

Control.propTypes = {
  /**
   * Children to render.
   */
  children: PropTypes.node,
  /**
   * The mouse down event and the innerRef to pass down to the controller element.
   */
  innerProps: PropTypes.shape({
    onMouseDown: PropTypes.func.isRequired,
  }).isRequired,
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]).isRequired,
  selectProps: PropTypes.object.isRequired,
}

function Option(props: any) {
  const { data } = props

  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected ? 500 : 400,
      }}
      {...props.innerProps}
    >
      <Grid container direction="row" alignItems="center" justifyContent="flex-start">
        {/* eslint-disable-next-line react/prop-types */}
        {data.icon ? (
          <Grid item>
            <img src={data.icon} alt="" className={classes.menuItemLogo} />
          </Grid>

        ) : null}
        <Grid item className={classes.menuItemText}>
          {props.children}
        </Grid>
      </Grid>
    </MenuItem>
  )
}

Option.propTypes = {
  /**
   * The children to be rendered.
   */
  data: PropTypes.object.isRequired,
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.shape({
    id: PropTypes.string.isRequired,
    key: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    onMouseMove: PropTypes.func.isRequired,
    onMouseOver: PropTypes.func.isRequired,
    tabIndex: PropTypes.number.isRequired,
  }).isRequired,
  /**
   * Inner ref to DOM Node
   */
  innerRef: PropTypes.oneOfType([
    PropTypes.oneOf([null]),
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.any.isRequired,
    }),
  ]),
  /**
   * Whether the option is focused.
   */
  isFocused: PropTypes.bool.isRequired,
  /**
   * Whether the option is selected.
   */
  isSelected: PropTypes.bool.isRequired,
}

function Placeholder(props: any) {
  const { selectProps, innerProps = {}, children } = props
  return (
    <Typography color="textSecondary" className={selectProps.classes.placeholder} {...innerProps}>
      {children}
    </Typography>
  )
}

Placeholder.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  /**
   * props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
}

function SingleValue(props: any) {
  const { data, isDisabled } = props

  return (
    <Typography
      component="div"
      className={isDisabled ? classes.singleValueDisabled : classes.singleValue}
      {...props.innerProps}
    >
      <Grid container direction="row" alignItems="center" justifyContent="flex-start">
        {/* eslint-disable-next-line react/prop-types */}
        {data.icon ? (
          <Grid item>
            <img src={data.icon} alt="" className={classes.menuItemLogo} />
          </Grid>

        ) : null}
        <Grid item className={classes.menuItemText}>
          {props.children}
        </Grid>
      </Grid>
    </Typography>
  )
}

SingleValue.propTypes = {
  /**
   * The children to be rendered.
   */
  isDisabled: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  children: PropTypes.node,
  /**
   * Props passed to the wrapping element for the group.
   */
  innerProps: PropTypes.any,
  selectProps: PropTypes.object.isRequired,
}

function ValueContainer(props: any) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
}

ValueContainer.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
}

function MultiValue(props: any) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<CancelIcon {...props.removeProps} />}
    />
  )
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool.isRequired,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    onMouseDown: PropTypes.func.isRequired,
    onTouchEnd: PropTypes.func.isRequired,
  }).isRequired,
  selectProps: PropTypes.object.isRequired,
}

function Menu(props: any) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  )
}

Menu.propTypes = {
  /**
   * The children to be rendered.
   */
  children: PropTypes.element.isRequired,
  /**
   * Props to be passed to the menu wrapper.
   */
  innerProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
}

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
}

function AutoComplete(props: any) {
  const theme = useTheme()
  // const [selection, setSelection] = React.useState(null)
  const { id, placeholder, t, name, isMulti, value, setValid, required, onChange, disabled, options, label, ...rest } = props
  const { t: componentT } = useTranslation('autocomplete')

  const selectStyles = {
    input: (base: any) => ({
      ...base,
      color: disabled ? 'rgba(0,0,0,0.38)' : theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    container: (base: any) => ({
      ...base,
      color: disabled ? 'rgba(0,0,0,0.38)' : theme.palette.text.primary,
    }),
  }
  const handleChange = (evt: any) => {
    if (evt?.value) {
      onChange(evt.value)
    } else {
      const items = evt?.reduce((acc: any, it: any) => acc.concat([it.value]), [])
      onChange(items)
    }
    // onChange(evt?.value)
  }
  let selection: any
  if (isMulti) {
    selection = value?.map((val: any) => (options?.find((x: any) => x.value === val)))?.map((x: any) => ({
      ...x,
      label: x.shortLabel || x.label,
    }))
  } else {
    const option = options?.find((x: any) => x.value === value)
    if (option) selection = { ...option, label: option?.shortLabel || option?.label }
    else selection = null
  }
  useEffect(() => {
    if (required) {
      if (typeof value === 'undefined' || value == null) setValid(false)
      else setValid(Boolean(selection))
    } else {
      setValid(true)
    }
  }, [value, required])
  const fieldLabel = label || t(name)

  return (
    <NoSsr>
      <StyledWrapper className={classes.wrapper}>
        <Select
          classes={classes}
          styles={selectStyles}
          inputId={id}
          isClearable
          TextFieldProps={{
            label: `${fieldLabel} ${required ? '*' : ''}`,
            InputLabelProps: {
              htmlFor: id,
              shrink: true,
            },
          }}
          isDisabled={disabled}
          isSearchable
          placeholder={placeholder || `${fieldLabel} ${componentT('placeholder')}`}
          options={options}
          components={components}
          value={selection === undefined ? null : selection}
          onChange={handleChange}
          isMulti={isMulti}
          onKeyDown={(evt: any) => {
            if (evt.keyCode === 13) {
              evt.stopPropagation()
            }
          }}
          {...rest}
        />
      </StyledWrapper>

    </NoSsr>
  )
}

AutoComplete.propTypes = {
  isMulti: PropTypes.bool,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    shortLabel: PropTypes.string,
  })).isRequired,
  disabled: PropTypes.bool,
  setValid: PropTypes.func,
  required: PropTypes.bool,
}
AutoComplete.defaultProps = {
  isMulti: false,
  disabled: false,
  placeholder: '',
  required: false,
  setValid: () => {
  },
}
export default AutoComplete
