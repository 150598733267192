/* eslint-disable */
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState, AppThunk} from '../../app/store'
import Cookies from 'js-cookie'

export interface ICookieState {
  necessary: boolean,

}

export interface DataProtectionState {
  cookies: ICookieState,
  show: boolean,
  variant: 'bottom',
}

const initialState: DataProtectionState = {
  cookies: {
    necessary: false,
  },
  show: true,
  variant: 'bottom',
}

export const index = createSlice({
  name: 'dataProtection',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setCookies: (state, action: PayloadAction<ICookieState>) => {
      state.cookies = action.payload
      state.show = false
    },
  },

})

const {setCookies} = index.actions

export const getAcceptedCookies = (): AppThunk => (dispatch, getState) => {
  const cookieString = Cookies.get('data-protection')
  if (cookieString) {
    try {
      const cookies = JSON.parse(cookieString)
      dispatch(setCookies(cookies))
    } catch (e) {
      // do nothing
    }

  }

}
export const acceptCookies = (cookies: ICookieState): AppThunk => (dispatch, getState) =>{
  try {
    const cookieString = JSON.stringify(cookies)
    Cookies.set('data-protection', cookieString)
    dispatch(setCookies(cookies))
  } catch (e) {
    // do nothing
  }
}

export const selectCookies = (state: RootState) => state.dataProtection.cookies


export default index.reducer
