import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import TextField from './TextField'

function UserNameField(props: any) {
  const { value, name, onChange, setValid, ...rest } = props
  const { t } = useTranslation('userNameField')
  const [error, setError] = useState('')
  const [initial, setInitial] = useState(true)

  useEffect(() => {
    if (initial) {
      setInitial(false)
      if (props.required && !value) setValid(false)
      return
    }
    const userNameRegex = /^([A-Z@a-z0-9\\.]+)$/igm
    if (!userNameRegex.test(value)) {
      setError(t('allowedChars'))
      setValid(false)
      return
    }
    setValid(true)
    setError('')
  }, [t, value])
  return (

    <TextField
      t={t}
      type="text"
      error={error}
      {...props}
    />
  )
}

UserNameField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  setValid: PropTypes.func.isRequired,
}

export default UserNameField
