import { styled } from '@mui/material/styles'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import { MouseEvent } from 'react'

export interface IBreadcrumbsProps {
  color: 'black'|'white'
}
export interface ILinkStack {
  to?: string,
  text: string,
  onClick?: (evt: MouseEvent<HTMLButtonElement>)=>void
}
export interface IProps extends IBreadcrumbsProps {
  linkStack: ILinkStack[],
}
const StyledBreadCrumbs = styled(Breadcrumbs)<IBreadcrumbsProps>(({ theme, color }) => ({
  color,
}))
export default StyledBreadCrumbs
