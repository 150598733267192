import { styled } from '@mui/material/styles'
import { TreeItem, TreeView } from '@mui/lab'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Checkbox as MaterialCheckbox, Collapse, Typography } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { animated, useSpring } from 'react-spring'

const PREFIX = 'Tree'

const classes = {
  labelRoot: `${PREFIX}-labelRoot`,
  labelIcon: `${PREFIX}-labelIcon`,
  labelText: `${PREFIX}-labelText`,
  checkBoxSmall: `${PREFIX}-checkBoxSmall`,
}

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`&.${classes.labelRoot}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },

  [`& .${classes.labelIcon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.labelText}`]: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },

  [`& .${classes.checkBoxSmall}`]: {
    margin: 0,
    padding: 0,
  },
}))

interface ITreeProps {
  label: string,
  name: string,
  value: any,
  t: any,
  disabled?: boolean,
  id: string,
  fields: Array<any>,
  onChange: any,
}

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  })

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  )
}

function FieldItem(props: any) {
  const { id, label, name, disabled, t, checked, handleClick, path, fields } = props
  // console.log('fieldItem', name, checked)
  const isChecked = typeof fields === 'undefined' ? checked : !Object.values(checked).includes(false)
  return (
    <TreeItem
      nodeId={id || name}
      // onClick={handleClick(path, name, !isChecked)}
      label={(
        <Root className={classes.labelRoot} onClick={handleClick(path, name, !isChecked)}>
          {/* <LabelIcon color="inherit" className={classes.labelIcon} /> */}
          <Typography variant="body2" className={classes.labelText}>
            {label || t(name)}
          </Typography>
          <MaterialCheckbox
            color="primary"
            disabled={disabled}
            indeterminate={fields && Object.values(checked).includes(true) && Object.values(checked).includes(false)}
            checked={isChecked}
            classes={{
              root: classes.checkBoxSmall,
            }}
          />

        </Root>
      )}
      TransitionComponent={TransitionComponent}
    >
      {fields?.map((field: any) => (
        <FieldItem
          key={field.name}
          classes={classes}
          path={path.concat(name)}
          handleClick={handleClick}
          checked={checked[field.name]}
          t={t}
          name={field.name}
          disabled={disabled}
          fields={field.fields}
          id={`${id || name}.${field.id || field.name}`}
        />
      ))}
    </TreeItem>
  )
}

const updateInner = (state: any, changeTo: boolean): any => {
  if (typeof state === 'boolean') return changeTo
  const update: any = {}
  Object.keys(state).forEach((key) => {
    update[key] = updateInner(state[key], changeTo)
  })
  return update
}
const nestedUpdate = (state: any, path: any[], changeTo: boolean): any => {
  if (path.length <= 1) {
    return {
      ...state,
      [path[0]]: updateInner(state[path[0]], changeTo),
    }
  }

  // update by key
  return {
    ...state,
    [path[0]]: nestedUpdate(state[path[0]], path.slice(1), changeTo),
  }
}

function Tree(props: ITreeProps) {
  const { label, t, name, fields, value, onChange, disabled } = props
  // const [itemState, setItemState] = useState(value || {})
  const handleClick = (path: any[], current: string, changeTo: boolean) => (event: MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    if (disabled) return
    const update = nestedUpdate(value, path.concat([current]), changeTo)
    // setItemState(update)
    onChange(update)
  }
  // console.log(itemState)
  return (
    <div>
      <Typography variant="subtitle1">{label || t(name)}</Typography>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        // multiSelect
        // selected={selected}
        // onNodeSelect={handleSelect}
      >
        {
          fields?.map((field: any) => (
            <FieldItem
              key={field.name}
              handleClick={handleClick}
              classes={classes}
              path={[]}
              t={t}
              checked={value[field.name]}
              disabled={disabled}
              {...field}
            />
          ))
        }
      </TreeView>
    </div>

  )
}

export default Tree
