import { BrowserRouter, useRoutes } from 'react-router-dom'
import routes from 'ui/routes'

function RouteView() {
  const elements = useRoutes(routes)
  return elements
}
export default function Router() {
  return (
    <BrowserRouter>
      <RouteView />
    </BrowserRouter>
  )
}
