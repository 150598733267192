import React from 'react'
import './App.css'
import { Provider } from 'react-redux'
import { I18nextProvider } from 'react-i18next'
import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { SnackbarProvider } from 'notistack'
import i18n from './i18n'
import { store } from './app/store'
import theme from './theme'
import Page from './ui/layout/Page'

function App() {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <SnackbarProvider
                maxSnack={3}
                autoHideDuration={5000}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <Page />
              </SnackbarProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </I18nextProvider>

    </Provider>

  )
}

export default App
