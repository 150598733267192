import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Grid, IconButton, Typography } from '@mui/material'
import { Add as AddIcon } from '@mui/icons-material'
import clsx from 'clsx'
import ResolveComponent from './ResolveComponent'
import SingleAccordion from './SingleAccordion'

const PREFIX = 'NestedForm'

const classes = {
  listItem: `${PREFIX}-listItem`,
  border: `${PREFIX}-border`,
  borderHeader: `${PREFIX}-borderHeader`,
}
const StyledBorder = styled('div')((
  {
    theme,
  }
) => ({

  marginTop: theme.spacing(2),
  border: '1px solid rgba(0, 0, 0, 0.12)',
  borderRadius: 10,
  padding: theme.spacing(2),

  [`& .${classes.borderHeader}`]: {
    color: 'rgba(0, 0, 0, 0.38)',
    position: 'absolute',
    marginTop: -30,
    marginLeft: -18,
    paddingRight: theme.spacing(1),
    background: 'white',
    fontWeight: 'bold',
  },
}))

interface IProps {
  elements: Array<any>,
  onSubmit?: any,
  className?: string,
  setValid: any,
  name?: string,
  disabled: boolean,
  onNewElement?: any,
  t: any,
  container?: any,
  value: any,
  disableAll: boolean,
  title2?: string,
  subHeader?: any,
  variant?: string,
}

function EmptyBox(props: any) {
  const {
    valid,
    setValid,
    elements,
    subHeader,
    name,
    value,
    onNewElement,
    className,
    onSubmit,
    t,
    container,
    disableAll,
  } = props
  return props.children
}

function BorderBox(props: any) {
  const {
    valid,
    setValid,
    elements,
    subHeader,
    name,
    value,
    onNewElement,
    className,
    onSubmit,
    t,
    container,
    disableAll,
  } = props
  return (
    <StyledBorder className={clsx(props.className, classes.border)}>
      <div className={classes.borderHeader}>
        {props.header}
      </div>
      <div>
        {props.children}
      </div>
    </StyledBorder>
  )
}

function NestedForm(props: IProps) {
  const { variant, elements, subHeader, name, value, onNewElement, className, onSubmit, t, container, disableAll } = props
  const submitRequest = (e: any) => {
    e.preventDefault()
    onSubmit()
  }
  const [valid, setValid] = useState({})
  const handleSetValid = (newVal: any) => {
    setValid((prev) => ({ ...prev, ...newVal }))
  }
  useEffect(() => {
    if (Object.values(valid).includes(false)) {
      props.setValid(false)
    } else {
      props.setValid(true)
    }
  }, [valid])
  let VariantComponent
  switch (variant) {
    case 'borderbox':
      VariantComponent = BorderBox
      break
    case 'none':
      VariantComponent = EmptyBox
      break
    case 'accordion':
    default:
      VariantComponent = SingleAccordion
      break
  }
  return (
    <VariantComponent
      className={classes.listItem}
      header={(
        <Typography variant="body1" className={clsx(className)}>
          {t(name)}{props.title2 || ''}
          {typeof onNewElement === 'function'
            && <IconButton onClick={onNewElement} size="large"><AddIcon /></IconButton>}
        </Typography>
      )}
      {...props}
      valid={valid}
      setValid={setValid}
      classes={classes}
    >
      <Grid container {...container}>
        {elements.map((element: any) => {
          const { type, hidden, grid: subGrid, ...elemProps } = element
          const elementType = typeof type === 'function' ? type(element, value) : type
          const [Component, additionalProps] = ResolveComponent(element, elementType, valid, handleSetValid, value, disableAll)

          if (Component === null) return null
          if (!value) {
            return null
          }
          return (
            <Grid item xs={12} {...subGrid} key={`form_nested_${element.index}_${element.id || element.name}`}>
              <Component
                t={t}
                {...elemProps}
                {...additionalProps}
                value={value[element.name]}
              />
            </Grid>

          )
        })}
      </Grid>
    </VariantComponent>
  )
}

NestedForm.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func,
  container: PropTypes.object,
  name: PropTypes.string,
  className: PropTypes.string,
  onNewElement: PropTypes.func,
  setValid: PropTypes.func,
  t: PropTypes.func.isRequired,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  disableAll: PropTypes.bool,
  title2: PropTypes.string,
  variant: PropTypes.string,
}
NestedForm.defaultProps = {
  onSubmit: () => {
  },
  disableAll: false,
  variant: 'accordion',
}
export default NestedForm
