import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material'
import ManagedForm from 'framework/components/ValidatedForm/ManagedForm'
import LoginComponent from './LoginComponent'

export default function Login() {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item>
        <LoginComponent />
      </Grid>
    </Grid>

  )
}
