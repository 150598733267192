import Grid from '@mui/material/Grid'
import { CircularProgress } from '@mui/material'

export default function LoadingCircle() {
  return (
    <div>
      <Grid container justifyContent="center" alignItems="center" alignContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    </div>
  )
}
