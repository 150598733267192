import { useState } from 'react'
import PropTypes from 'prop-types'
import ValidatedForm, { IProps as IValidatedFormProps } from './ValidatedForm'
import MultiDataLoader from '../DataLoader/MultiDataLoader'

interface IProps extends IValidatedFormProps {
  editMode: boolean,
  requiredProps?: Array<any>,
  getPropParams?: any,
}

function DataForm(props: IProps) {
  const { editMode, requiredProps, getPropParams, ...rest } = props
  const [isReady, setIsReady] = useState<undefined | boolean>(!requiredProps || requiredProps.length === 0)
  if (!isReady && requiredProps) {
    return (
      <MultiDataLoader
        setIsReady={setIsReady}
        requiredProps={requiredProps}
        parameters={getPropParams(props.value)}
      />
)
  }
  return <ValidatedForm disableAll={!editMode} {...rest} />
  /* if (editMode) {
    return <ValidatedForm {...rest} />
  }

  return <DisplayForm {...rest} /> */
}

DataForm.propTypes = {
  editMode: PropTypes.bool,
}
DataForm.defaultProps = {
  editMode: false,
}
export default DataForm
