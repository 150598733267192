import { styled } from '@mui/material/styles'
import { AppBar as MaterialAppBar, Grid, IconButton, Typography } from '@mui/material'
import Logout from './AppBarItems/Logout'

const StyledMaterialAppBar = styled(MaterialAppBar)(({ theme }) => ({
  backgroundColor: 'primary',
  [theme.breakpoints.up('lg')]: {
    zIndex: theme.zIndex.drawer + 1,
  },
  height: '52px',
}))
const StyledGrid = styled(Grid)(({ theme }) => ({
  height: '100%',
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}))
const FullHeightDiv = styled('div')(({ theme }) => ({
  height: '100%',
  width: '100%',
}))
const AppTitle = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  // paddingTop: theme.spacing(1),
}))

export default function AppBar() {
  return (
    <StyledMaterialAppBar position="fixed">
      <StyledGrid container direction="row" spacing={4} justifyContent="space-between" alignItems="center">
        <Grid item>
          <AppTitle>Tag Scanner</AppTitle>
        </Grid>
        <Grid item>
          <Logout />
        </Grid>
      </StyledGrid>
    </StyledMaterialAppBar>
  )
}
