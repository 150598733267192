import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { useTranslation } from 'react-i18next'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { Skeleton } from '@mui/material'
import LoadStateModel from 'framework/LoadStateModel'
import CircleVariant from './LoadingCircle'

const PREFIX = 'DataFetcher'

const classes = {
  icon: `${PREFIX}-icon`,
  paper: `${PREFIX}-paper`,
  skeletonRoot: `${PREFIX}-skeletonRoot`,
}

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.icon}`]: {
    marginRight: theme.spacing(1),
  },

  [`& .${classes.paper}`]: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },

  [`& .${classes.skeletonRoot}`]: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(8),
    // width: '100%',
    // flexGrow: 1
    // paddingTop: theme.spacing(16),
    // paddingLeft: theme.spacing(8),
  },
}))

interface IProps {
  loadState: LoadStateModel,
  variant?: 'skeleton' | 'circle'
}

export function SkeletonVariant() {
  return (
    <div className={classes.skeletonRoot}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={72} animation="wave" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={72} animation="wave" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={72} animation="wave" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={72} animation="wave" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={72} animation="wave" />
        </Grid>
        <Grid item xs={12}>
          <Skeleton variant="rectangular" height={72} animation="wave" />
        </Grid>
      </Grid>
    </div>
  )
}

function DataFetcher(props: IProps) {
  const { loadState: currentLoadState, variant } = props

  const { t } = useTranslation('error')
  const LoadingComponent = variant === 'skeleton' ? SkeletonVariant : CircleVariant
  return (
    (
      <Root>
        {
        (currentLoadState === LoadStateModel.LOADING || currentLoadState === LoadStateModel.INITIAL)
          ? (
            <LoadingComponent />
          ) : null
      }
        {currentLoadState === LoadStateModel.FAILED
        ? (
          <Grid container spacing={8}>
            <Grid item xs={8}>
              <Typography align="center" variant="h1">
                {t('title')}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Paper className={classes.paper}>
                <br />
                <Typography align="center">

                  {t('description')}
                </Typography>
                <br />
                <Typography align="center">
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    onClick={() => window.location.reload()}
                  >
                    {t('reloadPage')}
                  </Button>
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        )
        : null}
      </Root>
)
  )
}

DataFetcher.propTypes = {
  // requiredProp: PropTypes.string.isRequired,
  // parameters: PropTypes.array,
  loadState: PropTypes.any.isRequired,
  // loadingIndicator: PropTypes.bool,
}
DataFetcher.defaultProps = {
  // loadingIndicator: true,
  variant: 'skeleton',
}
export default DataFetcher
