import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Button, Grid } from '@mui/material'
import ResolveComponent from './ResolveComponent'

export interface IProps {
  elements: Array<any>,
  grid?: any,
  onSubmit?: any,
  t: any,
  setValid?: any,
  value: any,
  disableAll?: boolean,
  onChange?: (path:any) => (action:any)=> void
}

function ValidatedForm(props: IProps) {
  const { elements, setValid: parentSetValid, value, onSubmit, t, grid, disableAll, onChange } = props
  const submitRequest = (e: any) => {
    e.preventDefault()
    onSubmit()
  }
  const [valid, setValid] = useState({})
  const handleSetValid = useCallback((newVal:any) => {
    setValid((prev) => ({ ...prev, ...newVal }))
  }, [])
  // const handleSetValid = useCallback(() => (newVal: any) => {
  //   setValid((prev) => ({ ...prev, ...newVal }))
  // }, [setValid])
  useEffect(() => {
    // console.log('valid changed', valid)
    // console.log('form valid', valid)
    if (typeof parentSetValid === 'function') {
      if (Object.values(valid).includes(false)) {
        parentSetValid(false)
      } else {
        parentSetValid(true)
      }
    }
  }, [parentSetValid, valid])
  return (
    <form
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.stopPropagation()
          submitRequest(e)
        }
      }}
      onSubmit={(e) => {
        e.stopPropagation()
        submitRequest(e)
      }}
    >
      <Grid container spacing={2} {...grid} alignContent="flex-end" alignItems="flex-end">
        {elements.map((element: any) => {
          const { type, hidden, conditionalProps, grid: subGrid, ...elemProps } = element
          const elementType = typeof type === 'function' ? type(element, value) : type
          const conditionalElemProps = typeof conditionalProps === 'function' ? conditionalProps(element, value) : undefined
          // console.log('conditional', type, conditionalElemProps)
          const [Component, additionalProps] = ResolveComponent(element, elementType, valid, handleSetValid, value, Boolean(disableAll))
          if (Component === null) return null
          // if (element.type === 'nested') Object.assign(subGrid, undefined)
          return (
            <Grid item xs={12} {...subGrid} key={`form${element.id || element.name}`}>
              <Component
                id={`autocompleteform${element.id || element.name}`}
                t={Component === Button ? undefined : t}
                valid={valid}
                setValid={setValid}
                value={value[element.name]}
                onChange={typeof onChange === 'function' && onChange(element.name)}
                {...elemProps}
                {...conditionalElemProps}
                {...additionalProps}
              />
            </Grid>
          )
        })}
      </Grid>
    </form>
  )
}

ValidatedForm.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.object).isRequired,
  grid: PropTypes.object,
  onSubmit: PropTypes.func,
  t: PropTypes.func.isRequired,
  setValid: PropTypes.func,
  value: PropTypes.any,
  disableAll: PropTypes.bool,
}
ValidatedForm.defaultProps = {
  onSubmit: () => {
  },
  disableAll: false,
}
export default ValidatedForm
