import { Grid } from '@mui/material'
import LocationSelect from './LocationSelect'

export default function LocationSelectWrapper() {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} md={10}>
        <LocationSelect />
      </Grid>
    </Grid>
  )
}
