import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material'

const PREFIX = 'SingleAccordion'
const classes = {}

const Root = styled('div')((
  {
    theme,
  }
) => ({}))

function SingleAccordion(props: any) {
  const { header, hideChevron, children, ...rest } = props
  return (
    <Root className={props.className}>
      <Accordion {...rest}>
        <AccordionSummary
          expandIcon={hideChevron ? null : <ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          {props.header}
        </AccordionSummary>
        <AccordionDetails>
          {props.children}
        </AccordionDetails>
      </Accordion>
    </Root>
  )
}

SingleAccordion.propTypes = {
  header: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.string,
  expanded: PropTypes.any,
  hideChevron: PropTypes.bool,
  onChange: PropTypes.func,
}
SingleAccordion.defaultProps = {
  hideChevron: false,
}
export default SingleAccordion
