import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import TextField from './TextField'

interface IProps {
  value: string,
  name: string,
  onChange: any,
  setValid: any,
  comparePassword?: any,
  required: boolean,
}

function PasswordField(props: IProps) {
  const { value, required, setValid, comparePassword, ...rest } = props
  const { t, i18n } = useTranslation('passwordField')
  const [error, setError] = useState('')
  const [initial, setInitial] = useState(true)
  useEffect(() => {
    let checkError = ''
    if (initial) {
      setInitial(false)
      return
    }
    if (!value || value?.length === 0) {
      if (!required) {
        setValid(true)
        setError('')
        return
      }
      checkError = t('pleaseEnterPassword')
    }
    // else if (value?.length <= 8) {
    //   checkError = t('tooShort')
    // }

    // const uniqueChars = Object.values(value).filter((x: string, index: number) => value.indexOf(x) === index)
    // if (uniqueChars?.length < 4) {
    //   checkError = t('needsUniqueChars')
    // }
    // const specialCharCheck = /([^A-Za-z0-9])/gm
    // if (!specialCharCheck.test(value)) {
    //   checkError = t('needsSpecialChar')
    // }
    // const numberTest = /([0-9])/gm
    // if (!numberTest.test(value)) {
    //   checkError = t('needsNumber')
    // }
    // const upperCaseTest = /([A-Z])/gm
    // if (!upperCaseTest.test(value)) {
    //   checkError = t('needsUpperCase')
    // }
    // const lowerCaseTest = /([a-z])/gm
    // if (!lowerCaseTest.test(value)) {
    //   checkError = t('needsLowerCase')
    // }
    // if (comparePassword !== '' && comparePassword !== value) {
    //   checkError = t('passwordDifferent')
    // }

    setValid(!checkError)
    setError(checkError)
  }, [i18n.language, value])
  useEffect(() => {
    setValid(false)
    setError('')
    props.onChange('')
  }, [comparePassword])
  return (
    <TextField
      t={t}
      type="password"
      error={error}
      value={value}
      required={required}
      {...rest}
    />
  )
}

PasswordField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  comparePassword: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setValid: PropTypes.func.isRequired,
  required: PropTypes.bool,
}
PasswordField.defaultProps = {
  value: '',
  comparePassword: '',
  required: false,
}

export default PasswordField
