import { useCallback, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { StaticDatePicker, PickersDay } from '@mui/x-date-pickers'
import { Grid, TextField, Typography } from '@mui/material'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import { isSameDay } from 'date-fns'
import { PickersDayProps } from '@mui/lab'
import { yncoris } from '../../../theme'
import { ILocationDateCheckinCount } from '../../../features/locations'

const Root = styled('div')((
  {
    theme,
  }
) => ({
  // paddingTop: theme.spacing(2),
  // overflow: 'hidden',
  paddingRight: theme.spacing(1),
  // marginLeft: theme.spacing(1),
}))
type CustomPickerDayProps = PickersDayProps<Date> & {
  dayHasData: boolean;
  dayHasStillCheckedIn: boolean;
};
const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})<CustomPickerDayProps>(({ theme, dayHasData, dayHasStillCheckedIn }) => ({
  ...(dayHasData && {
    borderRadius: 25,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(dayHasStillCheckedIn && {
    borderRadius: 25,
    backgroundColor: yncoris.orange,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: yncoris.orange,
    },
  }),
}))
const RoundedBoxLegendStillCheckedIn = styled('div')(({ theme }) => ({
  borderRadius: 25,
  backgroundColor: yncoris.orange,
  color: theme.palette.common.white,
  width: theme.spacing(3),
  height: theme.spacing(3),
  '&:hover, &:focus': {
    backgroundColor: yncoris.orange,
  },
}))
const RoundedBoxLegendDayHasData = styled('div')(({ theme }) => ({
  borderRadius: 25,
  width: theme.spacing(3),
  height: theme.spacing(3),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  '&:hover, &:focus': {
    backgroundColor: theme.palette.primary.dark,
  },
}))
const RoundedOutlineBoxLegendToday = styled('div')(({ theme }) => ({
  borderRadius: 25,
  border: '1px solid',
  width: theme.spacing(3),
  height: theme.spacing(3),
  // backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  '&:hover, &:focus': {
    // backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.main,
  },
}))
function RenderDay(
  date: DateTime,
  selectedDates: Array<ILocationDateCheckinCount>|null,
  pickersDayProps: PickersDayProps<Date>,
) {
  // console.log('render day', date, selectedDates, pickersDayProps)
  const comp = date.toJSDate()
  const hasData = selectedDates?.find((x) => isSameDay(comp, new Date(x.id)))
  if (!hasData) { // @ts-ignore
    return <PickersDay {...pickersDayProps} />
  }
  // @ts-ignore
  return <CustomPickersDay {...pickersDayProps} dayHasData={hasData} dayHasStillCheckedIn={hasData?.checkedInCount > 0} />
}
const LegendGrid = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(2),
}))
function CheckinCalendar(props: any) {
  const { value, name, onChange, showLegend, required, setValid, disabled } = props
  const { t: genericT } = useTranslation('generic')
  const { t, i18n } = useTranslation('checkinCalendar')
  const [error, setError] = useState('')
  const [initial, setInitial] = useState(true)
  useEffect(() => {
    if (initial) {
      setInitial(false)
      if (!required) return
    }
    if (value === null || typeof value === 'undefined') {
      setError(t('noDate'))
      setValid(false)
      return
    }
    if (DateTime.isDateTime(value)) {
      setError(t('noValidDate'))
      setValid(false)
      return
    }
    setValid(true)
    setError('')
  }, [t, value])
  const renderDayFunction = useCallback(
(date: DateTime, selectedDates: Array<DateTime|null>, pickersDayProps: PickersDayProps<DateTime>) => RenderDay(date, value, pickersDayProps),
   [value]
  )
  // @ts-ignore
  return (
    <Root>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={8} lg={8}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            label={t('calendarTitle')}
            value={value}
            onChange={(newValue) => {
              // onChange(newValue)
            }}
            renderDay={renderDayFunction}
            renderInput={(params) => <TextField {...params} />}
            inputFormat="'Week of' MMM d"
          />
        </Grid>
        {showLegend && (
          // <LegendGrid item>
        <Grid xs={12} sm={4} lg={8} item container direction="column" spacing={2}>

          <Grid item container spacing={2}>
            <Grid item> <RoundedOutlineBoxLegendToday />
            </Grid>
            <Grid item>
              <Typography>{t('today')}</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item> <RoundedBoxLegendDayHasData />
            </Grid>
            <Grid item>
              <Typography>{t('dayHasData')}</Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item> <RoundedBoxLegendStillCheckedIn />
            </Grid>
            <Grid item>
              <Typography>{t('stillCheckedInUsers')}</Typography>
            </Grid>
          </Grid>
        </Grid>
          // </LegendGrid>
        )}

      </Grid>

    </Root>
  )
}

export default CheckinCalendar
