import PropTypes from 'prop-types'
import MaterialDialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'

import { Button, Grid } from '@mui/material/'
import DialogTitle from '@mui/material/DialogTitle'
import Typography from '@mui/material/Typography'
import { TFunction } from 'react-i18next'
import { PropsWithChildren, useCallback, MouseEvent } from 'react'
import { styled } from '@mui/material/styles'
import { DialogProps } from '@mui/material/Dialog/Dialog'

interface IProps extends DialogProps {
  t: TFunction<string, undefined>,
  dialogTitle: string,
  open: boolean,
  cancelButton: string,
  submitButton: string,
  cancelFunction: (event?: MouseEvent<HTMLButtonElement>)=>void
  submitFunction: (event?: MouseEvent<HTMLButtonElement>)=>void
  onlySubmitButton?: boolean,
}
const StyledMaterialDialog = styled(MaterialDialog)({
  minWidth: 400,

})
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
}))
const StyledDialogTitleTypography = styled(Typography)({
  color: 'rgba(0, 0, 0, 0.87)',
})
const StyledDialogContent = styled(DialogContent)({
  color: 'rgba(0, 0, 0, 0.87)',
})
function Dialog({
                  t,
                  children,
                  open,
                  dialogTitle,
                  onlySubmitButton,
                  cancelButton,
                  submitButton,
                  cancelFunction,
                  submitFunction,
                  ...rest
                }: PropsWithChildren<IProps>) {
  const handleClose = useCallback(() => {
    document.body.removeAttribute('style')
    cancelFunction()
  }, [cancelFunction])
  const handleCancel = useCallback((event?: MouseEvent<HTMLButtonElement>) => {
    document.body.removeAttribute('style')
    cancelFunction(event)
  }, [cancelFunction])
  const handleSubmit = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    document.body.removeAttribute('style')
    submitFunction(event)
  }, [cancelFunction])
  return (
    <StyledMaterialDialog
      open={open}
      onClose={handleClose}
      // disableScrollLock
      PaperProps={{
        style: {
          backgroundColor: '#fff',
        },
      }}
      {...rest}
    >
      <StyledDialogTitle>
        <StyledDialogTitleTypography align="center" variant="h6" gutterBottom>
          {dialogTitle}
        </StyledDialogTitleTypography>
      </StyledDialogTitle>
      <StyledDialogContent>
        {children}
      </StyledDialogContent>
      <DialogActions>
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item>
            <Button
              onClick={handleSubmit}
              color="primary"
            >{t(submitButton)}
            </Button>
          </Grid>
          {(!onlySubmitButton) && (
            <Grid item>
              <Button
                onClick={handleCancel}
                color="primary"
              >{t(cancelButton)}
              </Button>
            </Grid>
          )}
        </Grid>

      </DialogActions>
    </StyledMaterialDialog>
  )
}

Dialog.propTypes = {
  t: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  cancelButton: PropTypes.string,
  submitButton: PropTypes.string,
  cancelFunction: PropTypes.func.isRequired,
  submitFunction: PropTypes.func.isRequired,
  onlySubmitButton: PropTypes.bool,
}
Dialog.defaultProps = {
  cancelButton: 'dialogCancelButton',
  submitButton: 'dialogSubmitButton',
  onlySubmitButton: false,
}
export default Dialog
