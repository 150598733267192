// eslint-disable-next-line no-shadow
enum LoadStateModel {
  INITIAL = 0,
  LOADING = 10,
  LOADED = 20,
  NOLICENSE = 40,
  FAILED = 500,
}

export default LoadStateModel
