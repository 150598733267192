import { CssBaseline, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import CookieBanner from 'framework/views/CookieBanner'
import Notifier from 'framework/Notifier'
import Router from './Router'
import { useAppDispatch } from '../../app/hooks'
import { loadTokenOnPageLoad } from '../../features/auth'
import AppBar from './AppBar'
import Footer from './Footer'

const StyledRouteWrapper = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(6),
  padding: theme.spacing(4),
  minHeight: '85vh',
}))
export default function Page() {
  const appDispatch = useAppDispatch()
  useEffect(() => {
    appDispatch(loadTokenOnPageLoad())
  }, [])
  return (
    <>
      <CssBaseline />
      <AppBar />
      <Notifier />
      <CookieBanner />
      <StyledRouteWrapper>
        <Router />
      </StyledRouteWrapper>
      <Footer />
    </>

  )
}
