import { useCallback, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import Typography from '@mui/material/Typography'
import { useDropzone } from 'react-dropzone'
import clsx from 'clsx'

const PREFIX = 'Dropzone'

const classes = {
  wrapperFocus: `${PREFIX}-wrapperFocus`,
  mt2: `${PREFIX}-mt2`,
}
const MarginTop2 = styled('div')((
  {
    theme,
  }
) => ({
  marginTop: theme.spacing(2),
}))

const Root = styled('div')((
  {
    theme,
  }
) => ({
  paddingTop: theme.spacing(2),
  border: '3px dashed rgba(0, 0, 0, 0.38)',
  borderRadius: 10,
  textAlign: 'center',
  margin: 'auto',
  width: '100%',
  minHeight: '20em',
  '&:focus': {
    border: '3px dashed #006b8d',
    outline: 'none',
  },
  '&data-dragActive=true': {
    border: '3px dashed #006b8d',
    outline: 'none',
  },

  [`& .${classes.wrapperFocus}`]: {
    border: '3px dashed #006b8d',
  },

  [`& .${classes.mt2}`]: {
    paddingTop: theme.spacing(8),
  },
}))

function Dropzone(props: any) {
  const {
    id,
    t,
    name,
    placeholder,
    isMulti,
    value,
    setValid,
    required,
    onChange,
    disabled,
    variant,
    options,
    label,
    maxSize,
    accept,
    ...rest
  } = props

  const onDrop = useCallback((acceptedFiles:any) => {
    const last = acceptedFiles.pop()
    if (typeof last === 'undefined' && required) setValid(false)
    else setValid(true)
    onChange(last)
  }, [])
  useEffect(() => {
    if (required) setValid(false)
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept, maxSize })
  const wrapperClassname = clsx(isDragActive ? classes.wrapperFocus : undefined)
  return (
    <Root
      sx={
        isDragActive ? ({
          border: '3px dashed #006b8d',
          outline: 'none',
        }) : {}
      }
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <Typography variant="body2">{t('dragFilesHereLine1')}</Typography>
      <Typography variant="body2">{t('dragFilesHereLine2')}</Typography>
      <Typography
        variant="body2"
      >{t('accept')}: {accept.reduce((acc: string, item: string, index: number) => (acc + (index > 0 ? ', ' : '') + item.split('/')[1]), '')}
      </Typography>
      {value && (
        <MarginTop2 >
          <Typography color="primary" variant="body1">{t('uploadedFile')}</Typography>
          <Typography color="primary" variant="body1">{value.name}</Typography>
        </MarginTop2>
      )}
    </Root>
  )
}

Dropzone.propTypes = {
  isMulti: PropTypes.bool,
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  setValid: PropTypes.func,
  required: PropTypes.bool,
  accept: PropTypes.array,
}
Dropzone.defaultProps = {
  disabled: false,
  required: false,
  setValid: () => {
  },
}
export default Dropzone
