/* eslint-disable */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, AppThunk } from 'app/store'
import Cookies from 'js-cookie'

export interface AuthState {
  token: string|undefined
}

const initialState: AuthState = {
  token: undefined
}

export const index = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setToken: (state, action: PayloadAction<string|undefined>) => {
      state.token = action.payload
    },
  },

})

const { setToken } = index.actions

export const removeToken = ():AppThunk => (dispatch,getState)=>{
  try{
    Cookies.remove("auth-token")
    dispatch(setToken(undefined))
  }
  catch(e){
    // do nothing
  }

}
export const addToken = (token:string):AppThunk => (dispatch,getState)=>{
  try{
    Cookies.set('auth-token', token)
    // Cookies.set('auth-expires', expires)
    dispatch(setToken(token))
  }
  catch(e){
    // do nothing
  }

}
export const isAuthorized = (state: RootState) => Boolean(state.auth.token)


export const getToken = () => {
  const token = Cookies.get('auth-token')
  return token

}
export const loadTokenOnPageLoad = ():AppThunk => (dispatch,getState)=>{
  const token = getToken()
  if(token) {
    dispatch(setToken(token))
  }
}
export default index.reducer
