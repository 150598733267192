import { useAppDispatch } from 'app/hooks'
import { useCallback, MouseEvent } from 'react'
import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { LogoutOutlined } from '@mui/icons-material'
import { useSelector } from 'react-redux'
import { isAuthorized, removeToken } from '../../../features/auth'

export default function Logout() {
  const dispatch = useAppDispatch()
  const { t } = useTranslation('appBar')
  const authorized = useSelector(isAuthorized)
  const logout = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    dispatch(removeToken())
  }, [dispatch])
  if (!authorized) return null

  return (
    <Button variant="outlined" color="secondary" onClick={logout}>
      <LogoutOutlined />
      {t('logout')}
    </Button>
  )
}
