import { useEffect } from 'react'
import ValidatedForm from './ValidatedForm'
import useManagedForm from './useManagedForm'

export interface IManagedFormProps {
  initial: any,
  elements: Array<any>,
  grid?: any,
  onSubmit: (values: any) => void,
  t: any,
  disableAll?: boolean,
  autoSubmit?: boolean,
  autoSubmitAfter?: number,
  refreshInitial?: boolean,
}

export default function ManagedForm({ initial, refreshInitial, onSubmit, ...rest }: IManagedFormProps) {
  const [value, handleChange, setAll] = useManagedForm(initial)
  useEffect(() => {
    if (refreshInitial) {
      setAll(initial)
    }
  }, [initial, refreshInitial])

  const handleSubmit = () => {
    onSubmit(value)
  }
  return (
    <ValidatedForm
      value={value}
      onChange={handleChange}
      onSubmit={handleSubmit}
      {...rest}
    />
  )
}
