/* eslint-disable */
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AppThunk, RootState} from 'app/store'
import {IBaseModel} from "../api/baseModel";
import LoadStateModel from "../framework/LoadStateModel";
import LocationRestModel from "api/locations"

export interface ILocationModel extends IBaseModel{
  name: string
}
export interface ILocationDateCheckinCount {
  id: string,
  checkedInCount: number,
}
export interface ILocationModelWithDates extends ILocationModel{
  name: string,
  dates: Array<ILocationDateCheckinCount>
}

export interface AuthState {
  locations: ILocationModelWithDates[],
  loadState: LoadStateModel
}

const initialState: AuthState = {
  locations: [],
  loadState: LoadStateModel.INITIAL,
}

export const index = createSlice({
  name: 'locations',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setLocations: (state, action: PayloadAction<ILocationModelWithDates[]>) => {
      state.locations = action.payload
      state.loadState = LoadStateModel.LOADED
    },
    setLoadingState: (state,action: PayloadAction<LoadStateModel>) =>{
      state.loadState = action.payload
    }
  },

})


export const areLocationsLoaded = (state: RootState) => Boolean(state.locations.loadState === LoadStateModel.LOADED)
export const locationSelector = (state: RootState) => state.locations.locations


export const fetchData = (locations: ILocationModel[]):AppThunk => async (dispatch,getState)=> {
  try {
    console.log('fetchLocations',1)
    const result = await LocationRestModel.getWithDates() as ILocationModelWithDates[]
    console.log('fetchLocations',result)
    dispatch(index.actions.setLocations(result))
  }
  catch (e) {

    console.error('fetchLocations',e)
    dispatch(index.actions.setLoadingState(LoadStateModel.FAILED))
  }
}
  export default index.reducer
