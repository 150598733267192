import { Card, CardContent, CardHeader, Divider, Grid, Hidden, Paper, Typography, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { styled, Theme } from '@mui/system'
import LoginForm from './LoginForm'

const StyledLogo = styled('img')(({ theme }) => ({
  height: 50,
  [theme.breakpoints.up('md')]: {
    width: '100%',
    height: 'auto',
  },
}))
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),

}))
const NoPaddingGrid = styled(Grid)(({ theme }) => ({
  // padding: 0,
}))
const StyledDivider = styled(Divider)(({ theme }) => ({
  paddingLeft: theme.spacing(4),
  paddingTop: theme.spacing(4),
  paddingRight: 0,
  paddingBottom: 0,
  marginTop: theme.spacing(4),
  marginBottom: 0,
  // height: '100%',
  // marginTop: theme.spacing(1),
  // margonBottom: theme.spacing(1),
}))
export default function LoginComponent() {
  const { t } = useTranslation('login')
  return (
    <StyledPaper elevation={24}>
      <NoPaddingGrid container direction="row" spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={4}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item>
              <StyledLogo alt="logo" src="/logo.svg" />
            </Grid>
          </Grid>
        </Grid>
        <Hidden implementation="js" mdDown><StyledDivider flexItem orientation="vertical" variant="middle" /></Hidden>
        <Hidden implementation="js" mdUp><Divider flexItem orientation="horizontal" /></Hidden>
        <Grid item xs={12} md={7}>
          <LoginForm />

        </Grid>
      </NoPaddingGrid>

    </StyledPaper>
  )
}
