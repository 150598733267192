import { ReactElement } from 'react'
import { useSelector } from 'react-redux'
import Login from 'ui/views/Login'
import { useParams } from 'react-router'
import { isAuthorized } from '../../features/auth'
import useMultiDataLoader, { IRequiredProp } from '../../framework/components/DataLoader/useMultiDataLoader'
import LoadStateModel from '../../framework/LoadStateModel'
import LoadingCircle from '../../framework/components/DataLoader/LoadingCircle'

export default function AuthorizedRoute({ element, fetch, ...rest }: {fetch?:IRequiredProp[], element: ReactElement}) {
  const authorized = useSelector(isAuthorized)
  const params = useParams()
  const loadState = useMultiDataLoader({ requiredProps: fetch || [], parameters: params })
  // useMultiDataLoader(fetch,)

  if (!authorized) return (<Login />)
  if (loadState === LoadStateModel.LOADED) {
    return element
  }
  return <LoadingCircle />
}
