import { RestModel } from '../app/RestModel'
import { api } from '../app/apiClient'

const login = async (token: string) => {
  const result = await api.post('auth', { headers: { 'X-Api-Key': token } })
  return result.status === 200
}

class UserRestModel extends RestModel {
  path = 'User'

  login = login
}

export default new UserRestModel()
