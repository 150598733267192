export default {

  appBar: {
    logout: 'Abmelden',
  },
  imprint: {
    headline: 'Impressum',
    addressTitle: 'Kontakt',
    companyName: 'YNCORIS GmbH & Co. KG',
    addressLine: 'Industriestraße 300',
    location: '50354 Hürth',
    country: 'Deutschland',
    companyLeader: 'tbd',
    contactEmail: 'tbd@yncoris.com',
    ustId: 'Umsatzsteuer-ID: tbd',
  },
  cookieBanner: {
    cookies: 'Cookie',
    cookieText: 'Diese Webseite verwendet Cookies, um deine Anmeldung am System zu speichern.',
    dialogSubmitButton: 'Einstellung speichern',
    technicalNecessary: 'Technisch notwendige Cookies',
    acceptCookiesBeforeLoginIsPossible: 'Technisch notwendige Cookies akzeptieren, um den Login zu aktivieren.',
  },
  generic: {
    dateFormat: 'dd.MM.yyyy',
    dateTimeFormat: 'dd.MM.yyyy HH:mm',
    dateTimeFormatMask: '__.__.____ __:__',
  },
  emailField: {
    email: 'E-Mail',
    noValidEmail: 'E-Mail ungültig (email@yncoris.com)',
    noEmail: 'Bitte E-Mail eingeben',
  },
  userNameField: {
    allowedChars: 'Erlaubte Zeichen: A-Z, a-z, 0-9, . , @',
  },
  datePicker: {
    noDate: 'Bitte Datum auswählen',
    noValidDate: 'Ungültiges Datum',
    placeholder: 'DD.MM.YYYY',
    clear: 'Zurücksetzen',
    cancel: 'Abbrechen',
    ok: 'Übernehmen',
    tooEarly: 'Datum ist früher als {{minDate}} ',
  },
  passwordField: {
    password: 'Passwort',
    passwordConfirmation: 'Passwort wiederholen',
    pleaseEnterPassword: 'Bitte Passwort eingeben',
    tooShort: 'Zu kurz',
    passwordDifferent: 'Passwörter unterschiedlich',
    needsSpecialChar: 'Sonderzeichen benötigt',
    needsNumber: 'Zahl benötigt',
    needsLowerCase: 'Kleinbuchstabe benötigt',
    needsUpperCase: 'Großbuchstabe benötigt',
    needsUniqueChars: 'Mindestens 4 unterschiedliche Zeichen',
  },
  autocomplete: {
    placeholder: 'auswählen',
  },
  login: {
    password: 'Passwort',
    login: 'Anmelden',
  },
  location: {
    location: 'Standort',
    from: 'Von',
    to: 'Bis',
  },
  presences: {
    title: 'Anwesenheiten',
    stillPresent: 'Nicht ausgecheckt: {{present}} Person(en)',
    identifier: 'Tag-Seriennr',
    entry: 'Checkin',
    exit: 'Checkout',
    duration: 'Dauer (hh:mm)',
  },
  footer: {
    contact: 'Kontaktieren',
    imprint: 'Impressum',
    dataPrivacy: 'Datenschutz&shy;erklärung',
    aboutUs: 'Über uns',
    allRightsReserved: 'Alle Rechte vorbehalten',
    agb: 'AGB',
  },
  api: {
    networkError: 'Netzwerkfehler. Bitte Internetverbindung prüfen und ggf. Support kontaktieren. ',
    requestId: 'Id',
    validationError: 'Formular ungültig.',
    generic: 'Fehler: bitte nochmal versuchen',
    NOT_LOGGED_IN: 'Nicht eingeloggt, bitte zuerst anmelden.',
    passwordChangedSuccessful: 'Passwort wurde erfolgreich geändert',
    TODO: 'Noch nicht implementiert',
    corruptFile: 'Datei nicht lesbar oder falsches Format',
    PDF_REQUEST_TOO_LARGE: 'Zu viele Anfragen oder zu viele Dateien. Bitte Dateien reduzieren oder später erneut versuchen.',
    INVALID_LOGIN: 'User/Passwort Kombination falsch',
    invalidLogin: 'User/Passwort Kombination falsch',
    notReachable: 'Backend nicht erreichbar. Bitte Support kontaktieren',
  },
  checkinCalendar: {
    stillCheckedInUsers: 'Personen nicht ausgecheckt',
    dayHasData: 'Anwesenheiten erfasst',
    today: 'Heute',
    calendarTitle: 'Anwesenheitskalender',
  },
}
