import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useMemo } from 'react'
import LoadStateModel from 'framework/LoadStateModel'
import { useAppDispatch } from '../../../app/hooks'

export interface IRequiredNestedProp {
  prop: string,
  key: string,
}
export interface IRequiredProp {
  prop: string,
  nested?: Array<IRequiredNestedProp>
}

export interface IPropsLoader {
  requiredProps: Array<IRequiredProp>,
  parameters: any,
  loadWithUndefinedParameter?: boolean,
}
function nestedResolver(parameters: any, item: any, nested: any) {
  for (let z = 0; z < nested.length; z += 1) {
    const check = nested[z]
    const result = item[check.prop]
    const compVal = parameters[check.key] // get comparison value from url
    if (!result) return false
    if (!result[compVal]) {
      return false
    }
  }
  return true
}
function usePropIsReady(props: any): LoadStateModel {
  const { requiredProps, parameters } = props
  const state = useSelector((store) => store)
  return useMemo(() => {
    let ready = LoadStateModel.LOADED
    requiredProps?.forEach((requiredProp: any) => {
      // @ts-ignore
      const propState = state[requiredProp.prop]
      // console.log('isReady', ready, propState, requiredProps, parameters)
      if (propState.loadState !== LoadStateModel.LOADED) ready = propState.loadState
      if (requiredProp.nested && !nestedResolver(parameters, propState, requiredProp.nested)) {
        ready = LoadStateModel.INITIAL
      }
    })
    return ready
  }, [requiredProps, parameters, state])
}
export default function useMultiDataLoader(props:IPropsLoader) {
  const { requiredProps, parameters, loadWithUndefinedParameter } = props
  const dispatch = useAppDispatch()
  const loadState = usePropIsReady({ parameters, requiredProps })
  useEffect(() => {
    if (loadWithUndefinedParameter && Object.values(parameters).includes(undefined)) {
      return
    }
    if ([LoadStateModel.INITIAL, LoadStateModel.FAILED].includes(loadState)) {
      requiredProps.forEach((requiredProp) => {
        import(`features/${requiredProp.prop}`).then((module) => {
          // @ts-ignore
          dispatch(module.fetchData.apply(this, [parameters]))
        })
      })
    }
  }, [loadState, loadWithUndefinedParameter])
  return useMemo(() => loadState, [loadState])
}
