import Button from './Button'
import AutoComplete from './AutoComplete'
import Checkbox from './Checkbox'
import DatePicker from './DatePicker'
import NumberField from './NumberField'
import TextField from './TextField'
import EmailField from './EmailField'
import PasswordField from './PasswordField'
import FileUpload from './FileUpload'
import NestedForm from './NestedForm'
import FloatField from './FloatField'
import NestedList from './NestedList'
import ProposalField from './ProposalField'
import Tree from './Tree'
import Dropzone from './Dropzone'
import UserNameField from './UserNameField'
import DateTimePicker from './DateTimePicker'
import CheckinCalendar from './CheckinCalendar'

interface IProps {
  setValid?: any,
  disabled?: boolean,
  type?: string,
  value?: any,
  onChange?: any,
  disableAll?: boolean,
  parent?: any,
  title2?: any,
  onClick?: any,
}

export default function ResolveComponent(element: any, type: string, valid: any, setValid: any, value: any, disableAll: boolean): Array<any> {
  let Component
  const props: IProps = {}
  if (disableAll) {
    props.disabled = true
  }
  // const elementType = typeof element.type === 'function' ? element.type(element,element.value) : element.type
  switch (type) {
    case 'tree':
      Component = Tree
      props.disableAll = disableAll
      props.onChange = element.onChange
      break
    case 'list':
      Component = NestedList
      props.disableAll = disableAll
      props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      props.onChange = element.onChange
      break
    case 'autocomplete':
      Component = AutoComplete
      if (value === undefined) props.value = null
      props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      break
    case 'proposal':
      Component = ProposalField
      props.disabled = disableAll
      if (value === undefined) props.value = null
      props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      break
    case 'dropzone':
      Component = Dropzone
      props.disabled = disableAll
      // if (value === undefined) props.value = null
      props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      break
    case 'checkbox':
      Component = Checkbox
      break
    case 'group':
      Component = NestedForm
      props.disableAll = disableAll
      // props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      if (typeof element.nameLookup === 'function') {
        props.title2 = element.nameLookup(value)
      }
      // props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      break
    case 'object':
      Component = NestedForm
      props.disableAll = disableAll
      if (typeof element.nameLookup === 'function') {
        props.title2 = element.nameLookup(value)
      }
      props.parent = value
      props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      break
    // should not be nested in react style
    // case 'list':
    //   Component = NestedForm
    //   elemProps.setValid = (isValid: boolean) => setValid((prev) => ({ ...prev, [element.name]: isValid }))
    //   break
    case 'checkinCalendar':
      Component = CheckinCalendar
      props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      break
    case 'date':
      Component = DatePicker
      props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      break
    case 'datetime':
      Component = DateTimePicker
      props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      break
    case 'float':
      Component = FloatField
      props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      // elemProps.type = 'number'
      break
    case 'number':
      Component = NumberField
      props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      // elemProps.type = 'number'
      break
    case 'text':
      Component = TextField
      props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      break
    case 'userName':
      Component = UserNameField
      props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      break
    case 'email':
      Component = EmailField
      props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      break
    case 'password':
      Component = PasswordField
      props.setValid = (isValid: boolean) => setValid({ ...valid, [element.name]: isValid })
      break
    case 'file':
      Component = FileUpload
      break
    case 'lookupButton':
      Component = Button
      props.onClick = async (event: any) => {
        const result: any[] = await element.onClick(value, event)
        if (result && typeof result !== 'undefined' && typeof element.setState === 'function') result.forEach((it) => element.setState(it))
      }
      break
    case 'button':
      Component = Button
      props.setValid = undefined
      // t=undefined
      // Component.children = element.label
      break
    case 'submit':
      Component = Button
      props.type = 'submit'
      if (Object.values(valid).includes(false)) {
        props.disabled = true
      }
      // elemProps.disabled =
      // Component.children = element.label
      break
    case 'hidden':
    default:
      return [null, undefined]
  }
  return [Component, props]
}
