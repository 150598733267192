import { RouteObject } from 'react-router/lib/router'
import Login from 'ui/views/Login'
import LocationSelect from 'ui/views/LocationSelect'
import AuthorizedRoute from 'ui/layout/AuthorizedRoute'
import { ReactElement } from 'react'
import { IRequiredProp } from '../framework/components/DataLoader/useMultiDataLoader'
import LocationPresence from './views/Presence'

export interface Route extends RouteObject {
  authorized?: boolean
  element: ReactElement,
  fetch?: IRequiredProp[]
}

const routes: Route[] = [
  {
    path: '/',
    element: (<Login />),
  },
  {
    path: '/locations',
    authorized: true,
    element: <LocationSelect />,
    fetch: [
      {
        prop: 'locations',
      },
    ],
  },
  {
    path: '/locations/:locationId/from/:from/to/:to',
    authorized: true,
    element: <LocationPresence />,
    fetch: [
      {
        prop: 'locations',
      },
    ],
  },
]

export default routes.map((x) => {
  if (x.authorized) {
    return {
      ...x,
      element: <AuthorizedRoute {...x} />,
    } as RouteObject
  }
  return x as RouteObject
})
