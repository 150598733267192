import { useReducer } from 'react'

function reassignNested(state: any, path: any[], value: any): any {
  if (path.length <= 1) {
    if (typeof path[0] === 'number') {
      if (typeof value === 'undefined') {
        if (typeof state === 'undefined') return []
        return [
          ...(state?.slice(0, path[0]) || []),
          // reassignNested(state[path[0]], path.slice(1), value),
          ...(state?.slice(path[0] + 1) || []),
        ]
      }
      if (typeof state === 'undefined') return [value]
      return [
        ...(state?.slice(0, path[0]) || []),
        value,
        // reassignNested(state[path[0]], path.slice(1), value),
        ...(state?.slice(path[0] + 1) || []),
      ]
    }
    return {
      ...state,
      [path[0]]: value,
    }
  }
  if (typeof path[0] === 'number') { // should be array here
    // update with position
    // console.log('path is number', state, path, value)

    if (typeof state === 'undefined') {
      // should be array but is undefined
      return [
        reassignNested([], path.slice(1), value),
      ]
    }
    return [
      ...state.slice(0, path[0]),
      reassignNested(state[path[0]], path.slice(1), value),
      ...state.slice(path[0] + 1),
    ]
  }
  // update by key
  return {
    ...state,
    [path[0]]: reassignNested(state[path[0]], path.slice(1), value),
  }
}

function reducer(state: any, action: any) {
  const { type, path, payload } = action
  switch (type) {
    case 'update':
      return reassignNested(state, path, payload)
    case 'setAll':
      return { ...action.payload }
    default:
      return state
  }
  // return state
}
interface ICustomPayload {
  path: any[]
  value: any
}
export type ManagedPayload = ICustomPayload|any

export default function useManagedForm(initial: any) {
  const [value, dispatch] = useReducer(reducer, initial)

  const updatePath = (path: any[]|any, action: any) => {
    if (Array.isArray(path)) dispatch({ path, type: 'update', payload: action })
    else dispatch({ path: [path], type: 'update', payload: action })
  }
  const handleChange = (name: any) => (action:ManagedPayload) => {
    if (!Array.isArray(action) && action?.path) updatePath([name].concat(action.path), action.value)
    else updatePath(name, action)
  }
  const setAll = (newVal:any) => {
    dispatch({ type: 'setAll', payload: newVal }) // update if store cahnges
  }
  return [value, handleChange, setAll]
}
