import { useSelector } from 'react-redux'
import MaterialTable from '@material-table/core'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { useParams } from 'react-router'
import { DateTime } from 'luxon'
import { Download, FileDownload } from '@mui/icons-material'
import { useCallback } from 'react'
import PresenceRestModel from 'api/presences'
import { IPresenceModel } from '../../../features/presences'
import { ILocationModel, locationSelector } from '../../../features/locations'
import BreadCrump from '../../../framework/components/BreadCrumb'
import fileDownload from '../../../lib/fileDownload'
import { apiUrl, timezoneOffset } from '../../../app/apiClient'

interface IPresenceTableProps {
  presences: IPresenceModel[],
  locationId: string,
  from: string,
  to: string,
}

function PresenceTableHeader({ presences, location }:{location: ILocationModel|undefined, presences: IPresenceModel[]}) {
  const { t } = useTranslation('presences')

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h6">{t('title')}: {location?.name}</Typography>

      </Grid>
      <Grid item>
        <Typography>{t('stillPresent', { present: presences.filter((x) => !x.exit).length })}</Typography>
      </Grid>
    </Grid>
  )
}
function BreadCrumbNavigationPresences({ location }: { location?: ILocationModel}) {
  const { from, to } = useParams()
  const { t } = useTranslation('generic')
  const fromDateString = DateTime.fromISO(from!).toFormat(t('dateTimeFormat'))
  const toDateString = DateTime.fromISO(to!).toFormat(t('dateTimeFormat'))
  return (
    <BreadCrump
      color="black"
      linkStack={[
        {
          text: 'Standortwahl',
          to: '/locations',
        },
        {
          text: `${location?.name} (${fromDateString} - ${toDateString})`,
        },
      ]}
    />
  )
}
export default function PresenceTable({ presences, locationId, from, to }: IPresenceTableProps) {
  const locations = useSelector(locationSelector)
  const location = locations.find((x) => x.id === locationId)
  const { t } = useTranslation('presences')

  const handleDownload = useCallback(() => {
    PresenceRestModel.getPresenceDownloadToken(locationId, from, to).then((token) => {
      fileDownload(`${apiUrl}presence/export?token=${encodeURIComponent(token)}&x-datetime-offset=${timezoneOffset}`)
    })
  }, [locationId, from, to])
  return (
    <Grid container direction="column">
      <BreadCrumbNavigationPresences location={location} />
      <MaterialTable
        title={<PresenceTableHeader location={location} presences={presences} />}
        columns={[
          { type: 'string', title: t('id'), field: 'id' },
          { type: 'string', title: t('identifier'), field: 'identifier' },
          { type: 'datetime', title: t('entry'), field: 'entry' },
          { type: 'datetime', title: t('exit'), field: 'exit' },
          { type: 'string', title: t('duration'), field: 'duration' },
        ]}
        actions={[
          {
            icon: FileDownload,
            tooltip: t('download'),
            isFreeAction: true,
            onClick: handleDownload,
          },
        ]}
        data={presences}
      />
    </Grid>

  )
}
