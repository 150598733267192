import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import TextField from './TextField'

function NumberField(props: any) {
  const { value, name, onChange, setValid, ...rest } = props
  const { t } = useTranslation('numberField')
  const [error, setError] = useState('')
  const [initial, setInitial] = useState(true)

  useEffect(() => {
    if (initial) {
      setInitial(false)
      if (props.required && typeof value !== 'number') setValid(false)
      return
    }
    if (value !== 0 && !value) {
      setError(t('noNumber'))
      setValid(false)
      return
    }
    if (value?.length === 0) {
      setError(t('noNumber'))
      setValid(false)
      return
    }
    const numberRegex = /^([0-9]*)$/igm
    if (!numberRegex.test(value)) {
      setError(t('noValidNumber'))
      setValid(false)
      return
    }
    setValid(true)
    setError('')
  }, [t, value])
  const stringValue = value ? String(value) : ''
  const displayValue = value === 0 ? '0' : stringValue
  return (
    <TextField
      t={t}
      type="number"
      error={error}
      value={displayValue}
      onChange={(val) => {
        if (/^([0-9]*)$/igm.test(val)) {
          const number = parseInt(val, 10)
          onChange(number)
        }
      }}
      name={name}
      {...rest}
    />
  )
}

NumberField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  setValid: PropTypes.func.isRequired,
}

export default NumberField
