import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
// import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'
import TextField from './TextField'

function EmailField(props: any) {
  const { value, name, onChange, required, setValid } = props
  const { t } = useTranslation('emailField')
  const [error, setError] = useState('')
  const [initial, setInitial] = useState(true)

  useEffect(() => {
    if (initial) {
      if (!required) {
        setValid(true)
        setError('')
        return
      }
      setInitial(false)
      setValid(false)
      return
    }
    if (!value || value?.length === 0) {
      if (!required) {
        setValid(true)
        setError('')
        return
      }
      setError(t('noEmail'))
      setValid(false)
      return
    }
    // eslint-disable-next-line no-control-regex
    const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/igm
    if (!emailRegex.test(value)) {
      setError(t('noValidEmail'))
      setValid(false)
      return
    }
    setValid(true)
    setError('')
  }, [t, value])
  return (
    <TextField
      t={t}
      type="email"
      error={error}
      {...props}
    />
  )
}

EmailField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  setValid: PropTypes.func.isRequired,
}
EmailField.defaultProps = {
  value: '',
}

export default EmailField
