import { Backdrop, Card, CardContent, CardHeader, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { useSnackbar } from 'notistack'
import { styled } from '@mui/system'
import loginApi from 'api/login'
import ManagedForm from '../../../framework/components/ValidatedForm/ManagedForm'
import { useAppDispatch } from '../../../app/hooks'
import { addToken } from '../../../features/auth'

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  // zIndex: theme.zIndex.drawer + 1,
  color: '#fff',
}))

export default function LoginForm() {
  const { t } = useTranslation('login')
  const navigate = useNavigate()
  const appDispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = useState(false)
  const onSubmit = useCallback(async (values: any) => {
    setLoading(true)
    try {
      const success = await loginApi.login(values.password)
      if (success) {
        appDispatch(addToken(values.password))
        navigate('/locations')
      } else {
        enqueueSnackbar(t('api:invalidLogin'), { variant: 'error' })
      }
    } catch (e) {
      console.error(e)
      enqueueSnackbar(t('api:invalidLogin'), { variant: 'error' })
    }

    setLoading(false)
  }, [])
  return (
    <>
      <StyledBackdrop open={loading}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
      <ManagedForm
        initial={{ from: new Date().toISOString(), to: new Date().toISOString(), location: undefined }}
        elements={[
          {
            type: 'password',
            name: 'password',
            required: true,
          },
          {
            type: 'submit',
            name: 'login',
            variant: 'contained',
          },
        ]}
        onSubmit={onSubmit}
        t={t}
      />
    </>

  )
}
