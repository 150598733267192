import { RestModel } from '../app/RestModel'
import { api } from '../app/apiClient'

const presenceByLocation = (locationId: string, from: string, to: string) => {
  const json = {
    locationId, from, to,
  }
  return api.post('presence', { json }).json()
}
const getPresenceDownloadToken = (locationId: string, from: string, to: string) : Promise<string> => {
  const json = {
    locationId, from, to,
  }
  return api.post('presence/export', { json }).text()
}
class PresenceRestModel extends RestModel {
  path = 'presence'

  presenceByLocation = presenceByLocation

  getPresenceDownloadToken = getPresenceDownloadToken
}

export default new PresenceRestModel()
