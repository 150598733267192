import { MouseEvent, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { Button, Divider, Grid, IconButton, Typography } from '@mui/material'
import { Add as AddIcon, Delete as DeleteIcon } from '@mui/icons-material'
import clsx from 'clsx'
import ResolveComponent from './ResolveComponent'
import SingleAccordion from './SingleAccordion'

const PREFIX = 'NestedList'

const classes = {
  listItem: `${PREFIX}-listItem`,
  deleteButton: `${PREFIX}-deleteButton`,
}

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`&.${classes.listItem}`]: {
    marginTop: theme.spacing(2),
  },

  [`& .${classes.deleteButton}`]: {
    marginBottom: theme.spacing(0),
  },
}))

interface IProps {
  elements: Array<any>,
  onSubmit?: any,
  className?: string,
  setValid: any,
  label?: any,
  name?: string,
  disabled: boolean,
  onNewElement?: any,
  t: any,
  container?: any,
  value: any,
  disableAll: boolean,
  BaseItem?: any,
  onChange: any,
  placeholder?: string,
}

function NestedList(props: IProps) {
  const {
    elements,
    name,
    label,
    value,
    BaseItem,
    onNewElement,
    className,
    onSubmit,
    t,
    container,
    disabled,
    disableAll,
  } = props
  const submitRequest = (e: any) => {
    e.preventDefault()
    onSubmit()
  }
  const [open, setOpen] = useState(false)
  const [valid, setValid] = useState<Array<any>>([])
  const handleSetValid = (index: number) => (newVal: any) => {
    setValid((prev) => [
      ...prev.slice(0, index),
      { ...prev[index], ...newVal },
      ...prev.slice(index + 1),
    ])
  }
  useEffect(() => {
    // console.log('list', name, valid)
    if (valid.findIndex((x: any) => Object.values(x).includes(false)) > -1) {
      // if (Object.values(valid).includes(false)) {
      props.setValid(false)
    } else {
      props.setValid(true)
    }
  }, [valid])
  const handleNewElement = (event: MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    const evt = new BaseItem()
    // elements[0].onChange(evt, value?.length || 0)
    props.onChange(evt, value?.length || 0)
    setOpen(true)
  }
  const handleDelete = (index: number) => (event: MouseEvent) => {
    event.stopPropagation()
    event.preventDefault()
    setValid((prev) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ])
    props.onChange(undefined, index)

    // onDelete(index)
  }
  return (
    <Root className={classes.listItem}>
      <Divider />
      <SingleAccordion
        expanded={open}
        onChange={() => setOpen((prev) => !prev)}
        className={classes.listItem}
        header={(
          <Grid container justifyContent="space-between" spacing={2}>
            <Grid item>
              <Typography variant="body1" className={clsx(className)}>
                {t(name)}
              </Typography>
            </Grid>
            {!disabled && (
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleNewElement}
                ><AddIcon /> {label?.newEntry || t('new')}
                </Button>
              </Grid>
            )}
          </Grid>

        )}
      >
        <Grid container {...container}>
          {(typeof value === 'undefined' || value?.length === 0) && props.placeholder ? (
            <Grid item>
              <Typography>{props.placeholder}</Typography>
            </Grid>
          ) : null}
          {value?.map((curVal: any, index: number) => (
            <Grid item xs={12} key={`form_nested_${index}`}>
              {index > 0 && <Divider />}
              <Grid key={`form_nested_list_${index}`} container {...container}>
                {elements.map((element: any) => {
                  const { type, hidden, grid: subGrid, ...elemProps } = element
                  const elementType = typeof type === 'function' ? type(element, curVal) : type
                  const [Component, additionalProps] = ResolveComponent(element, elementType, valid[index], handleSetValid(index), curVal, disableAll)

                  if (Component === null) return null
                  return (
                    <Grid
                      item
                      xs={12}
                      {...subGrid}
                      key={`form_nested_list_${index}_${element.index}_${element.id || element.name}`}
                    >
                      <Component
                        t={t}
                        {...elemProps}
                        {...additionalProps}
                        value={typeof element.getValue === 'function' ? element.getValue(value[index]) : value[index][element.name]}
                        onChange={(evt: any) => {
                          element.onChange(evt, index)
                        }}
                      />
                    </Grid>
                  )
                })}
                {!disabled && (
                  <Grid item key={`form_nested_list_${index}_delete`}>
                    <IconButton
                      className={classes.deleteButton}
                      onClick={handleDelete(index)}
                      size="large"
                    ><DeleteIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>

      </SingleAccordion>

    </Root>
  )
}

NestedList.propTypes = {
  elements: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.any,
  onSubmit: PropTypes.func,
  container: PropTypes.object,
  name: PropTypes.string,
  className: PropTypes.string,
  onNewElement: PropTypes.func,
  setValid: PropTypes.func,
  t: PropTypes.func.isRequired,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  disableAll: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
}
NestedList.defaultProps = {
  onSubmit: () => {
  },
  disableAll: false,
}
export default NestedList
