import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import { Grid } from '@mui/material'
import ManagedForm from '../../../framework/components/ValidatedForm/ManagedForm'
import { ILocationModel, locationSelector } from '../../../features/locations'
import useManagedForm from '../../../framework/components/ValidatedForm/useManagedForm'
import ValidatedForm from '../../../framework/components/ValidatedForm'
import CheckinCalendar from '../../../framework/components/ValidatedForm/CheckinCalendar'

const useLocationOptions = (locations:ILocationModel[]|undefined) => useMemo(() => locations?.map((x) => ({
      label: x.name,
      value: x.id,
    })), [locations])

export default function LocationSelect() {
  const { t } = useTranslation('location')
  const locations = useSelector(locationSelector)
  const locationOptions = useLocationOptions(locations)
  const navigate = useNavigate()

  const start = DateTime.fromJSDate(new Date()).set({ hour: 0, minute: 0, second: 0 }).toISO()
  const end = DateTime.fromJSDate(new Date()).set({ hour: 23, minute: 59, second: 59 }).toISO()
  const [values, handleChange] = useManagedForm({
    from: start,
    to: end,
    location: undefined,
    dates: [],
  })
  useEffect(() => {
    const dates = locations.find((x) => x.id === values.location)?.dates || []
    handleChange('dates')(dates)
  }, [values.location])
  const onSubmit = useCallback(() => {
    navigate(`/locations/${values.location}/from/${encodeURIComponent(values.from)}/to/${encodeURIComponent(values.to)}`)
  }, [navigate, values])
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} lg={5}>
        <CheckinCalendar
          showLegend
          disabled
          value={values.dates}
          valid
          setValid={() => {}}
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <ValidatedForm
          value={values}
          elements={[
            {
              type: 'autocomplete',
              name: 'location',
              options: locationOptions,
              required: true,
            },
            {
              type: 'datetime',
              name: 'from',
              required: true,
              // grid: { xs: 12, md: 6 },
            },
            {
              type: 'datetime',
              name: 'to',
              min: values.from,
              required: true,
            },
            {
              type: 'submit',
              name: 'Anzeigen',
              variant: 'contained',
            },
          ]}
          onChange={handleChange}
          onSubmit={onSubmit}
          t={t}
          editMode
        />
      </Grid>

    </Grid>

  )
}
