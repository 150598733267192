import { Button as MaterialButton, Tooltip } from '@mui/material'

export default function Button(props: any) {
  const { t, name, tooltip, valid, setValid, children, ...rest } = props
  const button = (
    <MaterialButton fullWidth {...rest} >
      {children || t(name)}
    </MaterialButton>
  )
  if (tooltip) {
    return (
      <Tooltip arrow title={tooltip} aria-label={`tooltip_${name}`}>
        {button}
      </Tooltip>
    )
  }
  return button
}
