import { useEffect, useState } from 'react'
import { styled, emphasize, useTheme } from '@mui/material/styles'
import PropTypes from 'prop-types'
import Autocomplete from '@mui/material/Autocomplete'
import NoSsr from '@mui/material/NoSsr'
import TextField from '@mui/material/TextField'
import { matchSorter } from 'match-sorter'
import useCachedUniqueList from '../../lib/useCachedUniqueList'

const PREFIX = 'AutoComplete'

const classes = {
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`,
  input: `${PREFIX}-input`,
  valueContainer: `${PREFIX}-valueContainer`,
  disabled: `${PREFIX}-disabled`,
  chip: `${PREFIX}-chip`,
  chipFocused: `${PREFIX}-chipFocused`,
  noOptionsMessage: `${PREFIX}-noOptionsMessage`,
  singleValue: `${PREFIX}-singleValue`,
  singleValueDisabled: `${PREFIX}-singleValueDisabled`,
  placeholder: `${PREFIX}-placeholder`,
  paper: `${PREFIX}-paper`,
  divider: `${PREFIX}-divider`,
  menuItemLogo: `${PREFIX}-menuItemLogo`,
  menuItemText: `${PREFIX}-menuItemText`,
  textFieldPopup: `${PREFIX}-textFieldPopup`,
}

const StyledWrapper = styled('div')((
  {
    theme,
  }
) => ({
  paddingTop: theme.spacing(2),

}))

function AutoComplete(props: any) {
  const theme = useTheme()
  // const [selection, setSelection] = React.useState(null)
  const {
    id,
    t,
    name,
    placeholder,
    isMulti,
    value,
    setValid,
    required,
    onChange,
    disabled,
    variant,
    options,
    label,
    ...rest
  } = props

  useEffect(() => {
    if (required) {
      if (typeof value === 'undefined' || value === '') setValid(false)
      else setValid(true)
    } else {
      setValid(true)
    }
  }, [value, required])
  const handleChange = (evt: any) => {
    onChange(evt.target.value || '')
  }
  const uniqueOptions = useCachedUniqueList(options)
  const filterOptions = (subOpt: any, { inputValue }: any) => matchSorter(subOpt, inputValue)
  return (
    <NoSsr>
      <StyledWrapper>
        <Autocomplete
          // id={id}
          freeSolo
          // autoSelect
          filterOptions={filterOptions}
          options={uniqueOptions}
          value={value || ''}
          disabled={disabled}
          noOptionsText={t('select:NoOptions')}
          onChange={(event: any, newValue: string | null) => {
            // setField(newValue || '')
            onChange(newValue || '')
          }}
          renderInput={(params) => (
            <TextField
              onChange={handleChange}
              {...{ ...params, InputLabelProps: { ...params.InputLabelProps, shrink: true } }}
              label={`${label || t(name)} ${required ? '*' : ''}`}
            />
          )}
        />
      </StyledWrapper>

    </NoSsr>
  )
}

AutoComplete.propTypes = {
  isMulti: PropTypes.bool,
  t: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    shortLabel: PropTypes.string,
  })),
  disabled: PropTypes.bool,
  setValid: PropTypes.func,
  required: PropTypes.bool,
}
AutoComplete.defaultProps = {
  isMulti: false,
  disabled: false,
  required: false,
  placeholder: '',
  setValid: () => {
  },
  options: [],
}
export default AutoComplete
