import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import PresenceRestModel from 'api/presences'
import { DateTime } from 'luxon'
import { IPresenceModel } from '../../../features/presences'
import LoadingCircle from '../../../framework/components/DataLoader/LoadingCircle'
import PresenceTable from './PresenceTable'

const loadData = async (locationId: string, from:string, to:string): Promise<IPresenceModel[]> => {
  const data = await PresenceRestModel.presenceByLocation(locationId, from, to)
  return data as IPresenceModel[]
}
export default function LocationPresence() {
  const { locationId, from, to } = useParams()
  const [presenceData, setPresenceData] = useState<IPresenceModel[]|undefined>()
  useEffect(() => {
    loadData(locationId!, from!, to!).then((data) => {
      if (data) {
        const mapped = data.map((x) => ({
          ...x,
          duration: x.exit && DateTime.fromISO(x.exit).diff(DateTime.fromISO(x.entry)).toFormat('hh:mm'),
        }))
        setPresenceData(mapped)
      }
    })
  }, [])
  if (presenceData) {
    return <PresenceTable locationId={locationId!} presences={presenceData} from={from!} to={to!} />
  }
  return <LoadingCircle />
}
