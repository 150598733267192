import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { SnackbarMessage, useSnackbar } from 'notistack'

const PREFIX = 'FileUpload'

const classes = {
  input: `${PREFIX}-input`,
  logo: `${PREFIX}-logo`,
  wrapper: `${PREFIX}-wrapper`,
}

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.input}`]: {
    display: 'none',
  },

  [`& .${classes.logo}`]: {
    maxWidth: '200px',
    maxHeight: '200px',
  },

  [`&.${classes.wrapper}`]: {
    marginTop: theme.spacing(2),
  },
}))

interface IProps {
  value: string,
  accept: string,
  name: string,
  t: any,
  id: string,
  onChange: any,
  disabled?: boolean,
}

function FileUpload(props: IProps) {
  const storeDispatcher = useDispatch()
  // Todo only jpg und png
  const { enqueueSnackbar } = useSnackbar()
  const { value, name, t, id, onChange, accept, disabled } = props
  const handleFileChange = ({ target }: any) => {
    // read input file as data url for images
    if (target.files.length > 0) {
      const fileReader = new FileReader()
      if (target.accept.includes(accept)) {
        fileReader.onload = (e) => {
          if (e.isTrusted) {
            onChange(fileReader.result)
          }
        }
        if (target.files[0].size > 256 * 1024) {
          console.error('file too big')
          const message = t('generic:fileTooBig')
          const snackbar = enqueueSnackbar(message, { variant: 'error' })
        } else fileReader.readAsDataURL(target.files[0])
      }
    }
  }

  return (
    <Root className={classes.wrapper}>
      <Grid container direction="column" alignItems="center">
        <Grid item>{t('maxFileSize')}</Grid>
        <Grid item>
          <img src={value} className={classes.logo} alt="" />
        </Grid>
        <Grid item>
          <input
            accept={accept}
            className={classes.input}
            id={`file-${name}-${id}`}
            onChange={handleFileChange}
            disabled={disabled}
            type="file"
            capture="user"
          />
          <label htmlFor={`file-${name}-${id}`}>
            <Button variant="contained" color="primary" component="span">
              {t('uploadButton')}
            </Button>
          </label>
        </Grid>
      </Grid>
    </Root>
  )
}

FileUpload.propTypes = {
  value: PropTypes.string,
  accept: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
FileUpload.defaultProps = {
  disabled: false,
}
export default FileUpload
