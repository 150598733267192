import { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'
import { DatePicker as MaterialDatePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

const PREFIX = 'DatePicker'

const classes = {
  picker: `${PREFIX}-picker`,
  wrapper: `${PREFIX}-wrapper`,
}

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`& .${classes.picker}`]: {
    width: '100%',
  },

  [`&.${classes.wrapper}`]: {
    paddingTop: theme.spacing(2),
    overflow: 'hidden',
  },
}))

function DatePicker(props: any) {
  const { value, name, onChange, required, setValid, disabled } = props
  const { t: genericT } = useTranslation('generic')
  const { t, i18n } = useTranslation('datePicker')
  const [error, setError] = useState('')
  const [initial, setInitial] = useState(true)

  useEffect(() => {
    if (initial) {
      setInitial(false)
      if (!required) return
    }
    if (value === null || typeof value === 'undefined') {
      setError(t('noDate'))
      setValid(false)
      return
    }
    if (DateTime.isDateTime(value)) {
      setError(t('noValidDate'))
      setValid(false)
      return
    }
    setValid(true)
    setError('')
  }, [t, value])
  // @ts-ignore
  return (
    <Root className={classes.wrapper}>
      <MaterialDatePicker
            // invalidLabel={t('noValidDate')}
        className={classes.picker}
            // cancelLabel={t('cancel')}
            // clearLabel={t('clear')}
            // okLabel={t('ok')}
        renderInput={(subProps) => <TextField fullWidth {...{ ...subProps, InputLabelProps: { ...subProps.InputLabelProps, shrink: true } }} label={`${props.t(name)} ${required ? '*' : ''}`} />}
            // label={props.t(name)}
        value={value ? DateTime.fromISO(value) : null}
            // placeholder={t('placeholder')}
        onChange={(date: DateTime|null) => {
          onChange(date?.toISODate() || null)
        }}
        inputFormat={genericT('dateFormat')}
        disabled={disabled}
      />
    </Root>
  )
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  setValid: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
}
DatePicker.defaultProps = {
  disabled: false,
}

export default DatePicker
