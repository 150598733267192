import { RestModel } from '../app/RestModel'
import { api } from '../app/apiClient'

const getWithDates = () => api.get('location/withdates').json()

class LocationRestModel extends RestModel {
  path = 'location'

  getWithDates = getWithDates
}

export default new LocationRestModel()
