import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import MaterialTextField from '@mui/material/TextField'
import { useEffect } from 'react'

const PREFIX = 'TextField'

const classes = {
  wrapper: `${PREFIX}-wrapper`,
  input: `${PREFIX}-input`,
  inputLabel: `${PREFIX}-inputLabel`,
  inputLabelShrunk: `${PREFIX}-inputLabelShrunk`,
}

const Root = styled('div')((
  {
    theme,
  }
) => ({
  [`&.${classes.wrapper}`]: {
    paddingTop: theme.spacing(2),
    overflow: 'hidden',
  },

  [`& .${classes.input}`]: {
    backgroundColor: 'white',
    borderRadius: 3,
  },

  [`& .${classes.inputLabel}`]: {
    zIndex: 1,
    color: 'rgba(0, 0, 0, .5)',
  },

  [`& .${classes.inputLabelShrunk}`]: {
    color: 'black !important',
  },
}))

function TextField(props: any) {
  const {
    name,
    label,
    type,
    maxRows,
    disabled,
    hideInPreview,
    required,
    multiline,
    readOnly,
    value,
    onChange,
    error,
    t,
    inputProps,
    setValid,
    setState,
    ...rest
  } = props
  useEffect(() => {
    if (typeof setValid === 'function') {
      if (required && (!value || value === '')) setValid(false)
      else setValid(true)
    }
  }, [required, value])
  return (
    <Root className={classes.wrapper}>
      <MaterialTextField
        // id={name}
        label={`${label || t(name)} ${required ? '*' : ''}`}
        value={value}
        type={type}
        maxRows={maxRows}
        multiline={multiline}
        disabled={disabled}
        onChange={(event) => onChange(event.target.value)}
        margin="none"
        fullWidth
        InputLabelProps={{ shrink: true }}
        error={Boolean(error)}
        helperText={error}
        // @ts-ignore // classes not assignable????
        InputProps={{
          classes: {
            readOnly,
          },
          ...inputProps,
        }}
        {...rest}
      />
    </Root>
  )
}

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  t: PropTypes.func.isRequired,
  type: PropTypes.string,
  maxRows: PropTypes.number,
  multiline: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  inputProps: PropTypes.any,
  label: PropTypes.string,
  setState: PropTypes.any,
  required: PropTypes.bool,
}

TextField.defaultProps = {
  type: 'text',
  error: '',
  maxRows: 1,
  multiline: false,
  readOnly: false,
}

export default TextField
